import {
  Box,
  Button,
  Typography
} from '@mui/material';
import React from 'react';

const StepLayout = ({
  children,
  onClick,
  onClickLabel = 'Next',
  onClickDisabled = false,
  title,
  secondaryOnClick,
  secondaryOnClickLabel,
  header
}) => {
  return (
    <>
      {header}
      {title && <Box mb={2}>
        <Typography variant="h2" component="h2">
          {title}
        </Typography>
      </Box>}
      <Box mb={4.5}>
        {children}
      </Box>
      {onClick && onClickLabel && (
        <Box mb={1}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={onClick}
            disabled={onClickDisabled}
            data-cy="primary-button"
          >
            {onClickLabel}
          </Button>
        </Box>
      )}
      {secondaryOnClick && secondaryOnClickLabel && (
        <Box mb={1}>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={secondaryOnClick}
          >
            {secondaryOnClickLabel}
          </Button>
        </Box>
      )}
    </>
  );
};

export default StepLayout;
