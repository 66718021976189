import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Typography
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import styled from 'styled-components';
import CheckTestKitCode from './CheckTestKitCode';
import InfoBox from './InfoBox';

const StyledInfoBox = styled(InfoBox)`
  display: none;

  @media (min-width: 600px) {
    display: block;
  }
`;

const Code = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [kitCode, setKitCode] = useState(null);
  const [initialKitCode, setInitialKitCode] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [acceptedPersonalInfo, setAcceptedPersonalInfo] = useState(false);

  useEffect(() => {
    const kc = searchParams.get('kc');
    if (kc) {
      setInitialKitCode(kc);
    }
  }, [searchParams]);

  const setKitAndBrand = (kitCode, json) => {
    setKitCode(kitCode);
  };

  const handleClick = () => {
    navigate(`/register/${kitCode}`);
  };

  return (
    <Box mt={10}>
      <Box mb={8} mx={3}>
        <Typography component="h1" variant="h1" align="center" color="primary">
          Your COVID-19 test certificate
        </Typography>
      </Box>
      <Box>
        <Box mb={2}>
          <Typography component="h2" variant="h2" gutterBottom>
            Enter your kit code
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography component="p" variant="body2">
            Your kit code is either printed on a separate card inside the pouch, printed in the box, or can be found in a PDF attached to your confirmation email, depending on when you bought your test.
          </Typography>
        </Box>
      </Box>
      <StyledInfoBox
        message="If you are registering a lateral flow test, we recommend opening this form on your mobile phone, as one of the steps involves uploading / taking a photo."
        title="Easier on mobile"
        centreIcon={false}
        level="info"
        medicspotLink=""
        outcomeDxLink=""
      />
      <Box>
        <Box>
          <CheckTestKitCode
            onSuccessChange={setIsSuccess}
            onValidKitCode={setKitAndBrand}
            initialKitCode={initialKitCode}
          />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          <FormControlLabel
            control={<Checkbox checked={acceptedPersonalInfo} onChange={(e) => setAcceptedPersonalInfo(e.target.checked)} name="confirmationProvided" />}
            label={<>By using this service, you agree to us using your sensitive personal information to process and issue a result. Please see our <Link href="https://tests.medicspot.co.uk/policies/terms-of-service" target="_blank">terms and conditions</Link>.</>}
            data-cy='accept-personal-info-checkbox'
          />
        </Box>
        <Button variant="contained" color="primary" fullWidth disabled={!isSuccess || !acceptedPersonalInfo} onClick={handleClick} data-cy='submit-kit-code'>Submit kit code</Button>
      </Box>
    </Box>
  );
};

export default Code;
