const handleChange = (validate, setLocalData) => (field) => eventData => {
  const value = eventData?.target ? eventData.target.value : eventData;
  const update = {
    [field]: value ?? ''
  };
  validate(field, value);
  setLocalData(current => ({...current, ...update}));
};

export default handleChange;
