import {
  Box
} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import styled from 'styled-components';
import makePathIndex from '../../utils/pathIndex';
import BulletNumberList from '../BulletNumberList';
import StepLayout from '../StepLayout';

const Image = styled.img`
  max-width: 100%;
  max-height: 600px;
`;

const WaitResult = ({
  path,
  setPathIndex,
  submissionData
}) => {
  const onNextStep = makePathIndex(path, setPathIndex);
  const timings = DateTime.local() < submissionData.testDate.plus({minutes: 30})
    ? <><strong>{submissionData.testDate.plus({minutes: 15}).toFormat('HH:mm')} and {submissionData.testDate.plus({minutes: 30}).toFormat('HH:mm')}</strong></>
    : <><strong>15 and 30 minutes</strong> after your test</>;

  return (
    <StepLayout
      onClick={onNextStep}
      onClickLabel="I have my result"
      title="Wait for your result"
      header={
        <Box mb={2.5} display="flex" justifyContent="center">
          <Image src="https://res.cloudinary.com/medicspot/image/upload/v1645110832/wait-result-background2x.png" alt="Layout" />
        </Box>
      }
    >
      <Box mb={3}>
        <BulletNumberList steps={['Wait 15 minutes for the result', <>Take a photo of your test between {timings}</>, 'Upload the photo (you can upload and finish the registration later)']} />
      </Box>
    </StepLayout>
  );
};

export default WaitResult;
