import {
  Box,
  Link,
  Typography
} from '@mui/material';
import React from 'react';
import BulletNumberList from '../BulletNumberList';
import StepLayout from '../StepLayout';

const FitToFlyLfdInstructions = ({onClick, certType}) => {
  const youWillNeedSteps = ['day2', 'day8', 'testToRelease'].includes(certType)
    ? [<>Your booking reference number. If you don’t have one you can <Link href="/register/booking" target="_blank">create a new booking reference number</Link></>, 'Your test kit', 'Your passport details', 'Your flight details']
    : ['Your test kit', 'Your passport details', 'Your flight details'];

  return (
    <StepLayout
      onClick={onClick}
      onClickLabel="Start"
      title="Before you start"
    >
      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="body1" component="p">
            Customers can take up to 10-20 minutes to fill in the form and take the test.
          </Typography>
        </Box>
        {certType === 'day2' && (
          <Box mb={2}>
            <Typography variant="body1" component="p">
              Day 2 test has to be done on or before 2 days after arrival in the UK.
            </Typography>
          </Box>
        )}
        {certType === 'day8' && (
          <Box mb={2}>
            <Typography variant="body1" component="p">
              Day 8 test has to be done on or after 8 days after arrival in the UK.
            </Typography>
          </Box>
        )}
        {certType === 'testToRelease' && (
          <Box mb={2}>
            <Typography variant="body1" component="p">
              Test to release is taken on the fifth day of arrival in the UK to shorten your quarantine.
            </Typography>
          </Box>
        )}
        {certType === 'fitToFly' && (
          <Box mb={2}>
            <Typography variant="body1" component="p">
              Don&apos;t start your test before it is time. Check rules on offical websites.
            </Typography>
          </Box>
        )}
      </Box>
      <Box mb={4}>
        <Box mb={2.5}>
          <Typography variant="h4" component="h4">
            Here are the steps
          </Typography>
        </Box>
        <BulletNumberList steps={['Register test kit  (5 - 10 min)', 'Take test (5 - 10 min)', 'Send your test to the lab']} />
      </Box>
      <Box mb={2.5}>
        <Typography variant="h4" gutterBottom component="h4">
          You will need
        </Typography>
        <BulletNumberList steps={youWillNeedSteps} />
      </Box>
    </StepLayout>
  );
};

export default FitToFlyLfdInstructions;
