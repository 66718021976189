import {
  Box,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const StyledStepper = styled(Stepper)`
  padding: 10px;
  background-color: rgba(48,127,226,0.08);

  .MuiStep-horizontal {
    padding: 0;
  }

  .MuiStepConnector-horizontal {
    margin: 0 8px;
  }
`;

const HorizontalLinearStepper = ({
  stages,
  currentIndex
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = stages;

  useEffect(() => {
    setActiveStep(currentIndex);
  }, [currentIndex]);

  return (
    <Box>
      <StyledStepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label} >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
    </Box>
  );
};

export default HorizontalLinearStepper;
