import {CameraAlt as CameraIcon} from '@mui/icons-material';
import {
  Box
} from '@mui/material';
import React, {
  useEffect,
  useState
} from 'react';
import styled from 'styled-components';
import BulletNumberList from '../BulletNumberList';
import StepLayout from '../StepLayout';

const Image = styled.img`
  max-width: 100%;
  max-height: 600px;
`;

const ReviewPhoto = ({
  setPathIndex,
  cassetteImage
}) => {
  const [image, setImage] = useState(null);
  const imageUrl = image ? URL.createObjectURL(image) : null;

  useEffect(() => {
    setImage(cassetteImage);
  }, [cassetteImage]);

  return (
    <StepLayout
      title="Review your image*"
      onClick={() => setPathIndex(current => current + 1)}
      onClickLabel="Submit photo"
      secondaryOnClick={() => setPathIndex(current => current - 1)}
      secondaryOnClickLabel={<><Box mr={1} display="flex"><CameraIcon /></Box>Retake photo</>}
      header={cassetteImage && (
        <Box mb={2.5} display="flex" justifyContent="center">
          <Image src={imageUrl} alt="Preview" />
        </Box>
      )}
    >
      <Box mb={3}>
        <Box mb={3}>
          <BulletNumberList steps={['Is the picture in focus?', 'Can the test result clearly be read?']} />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default ReviewPhoto;
