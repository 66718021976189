import {
  Box
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import CaptionText from '../CaptionText';
import DateTime from '../DateTime';
import StepLayout from '../StepLayout';

const SwabDetails = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {timeOfSwab = ''} = registrationData;
  const [localData, setLocalData] = useState({timeOfSwab});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Swab Details"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <CaptionText>When you have or will perform swab. This will be displayed on your certificate</CaptionText>
          <DateTime
            label="Date and time of swab"
            value={localData.timeOfSwab}
            error={errors.timeOfSwab}
            onChange={handleChange('timeOfSwab')}
            required
            data-cy='swab-picker'
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default SwabDetails;
