import {
  Box,
  Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const Names = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {firstName = '', middleName = '', lastName = ''} = registrationData;
  const [localData, setLocalData] = useState({firstName, middleName, lastName});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="What's your name?"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box mb={2}>
          <Typography variant="body1" component="p">
            Please give full, official name, as printed on your passport, ID, or other travel documents.
          </Typography>
        </Box>
        <Box>
          <TextField
            label="First name"
            name="firstName"
            value={localData.firstName}
            error={errors.firstName}
            onChange={handleChange('firstName')}
            inputProps={{maxLength: 50}}
            required
            data-cy='first-name-input'
          />
          <TextField
            label="Middle name(s)"
            name="middleName"
            value={localData.middleName}
            onChange={handleChange('middleName')}
            inputProps={{maxLength: 50}}
            data-cy='middle-name-input'
          />
          <TextField
            label="Last name"
            name="lastName"
            value={localData.lastName}
            error={errors.lastName}
            onChange={handleChange('lastName')}
            inputProps={{maxLength: 50}}
            required
            data-cy='last-name-input'
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default Names;
