import {Box} from '@mui/material';
import React, {
  useState, useEffect
} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import PhoneNumberField from '../PhoneNumberField';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const EmailAndPhone = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {email = '', repeatEmail = '', phoneNumber = ''} = registrationData;
  const [localData, setLocalData] = useState({email, repeatEmail, phoneNumber});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  useEffect(() => {
    if (localData.email.length && localData.repeatEmail.length) {
      const val = makeValidate(setErrors, {email: localData.email, repeatEmail: localData.repeatEmail});
      val('email', localData.email);
      val('repeatEmail', localData.repeatEmail);
    }
  }, [localData.email, localData.repeatEmail]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="What are your contact details?"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <TextField
            label="Email address"
            name="email"
            value={localData.email}
            error={errors.email}
            onChange={handleChange('email')}
            required
            data-cy="email-input"
          />
          <TextField
            label="Repeat email address"
            name="repeatEmail"
            value={localData.repeatEmail}
            error={errors.repeatEmail}
            onChange={handleChange('repeatEmail')}
            required
            data-cy="repeat-email-input"
          />
          <PhoneNumberField
            label="Phone number"
            name="phoneNumber"
            defaultCountry="gb"
            value={localData.phoneNumber}
            error={errors.phoneNumber}
            onChange={handleChange('phoneNumber')}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off'
              }
            }}
            required
            data-cy="phone-number-input"
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default EmailAndPhone;
