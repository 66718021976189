import {Box, Typography} from '@mui/material';
import React, {useState, useEffect} from 'react';
import ethnicities from '../../data/ethnicities';
import {genderOptions} from '../../data/genderOptions';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import DateInput from '../DateInput';
import RadioButtons from '../RadioButtons';
import Select from '../Select';
import StepLayout from '../StepLayout';

const PersonalInfo = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {dateOfBirth = '', gender = '', ethnicity = ''} = registrationData;
  const [localData, setLocalData] = useState({dateOfBirth, gender, ethnicity});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Your personal information"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <Typography variant="h4" component="h4" gutterBottom>Your date of birth*</Typography>
          <DateInput
            value={localData.dateOfBirth}
            error={!!errors.dateOfBirth}
            onChange={handleChange('dateOfBirth')}
            dataCy="dob-input"
          />
          <RadioButtons
            label="Your sex*"
            options={genderOptions}
            onChange={handleChange('gender')}
            value={localData.gender}
            error={errors.gender}
            required
            data-cy="gender-radio"
          />
          <Select
            label="Ethnicity"
            name="ethnicity"
            value={localData.ethnicity}
            error={errors.ethnicity}
            onChange={handleChange('ethnicity')}
            required
            data-cy="ethnicity-select"
            options={ethnicities}
            title="Your ethnicity*"
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default PersonalInfo;
