import {Link} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import excludedCountries from '../data/excludedCountries';
import {readFileAsArrayBuffer} from '../utils/fileReader';
import {daysInMonth} from './date';
import regex from './regex';
// import kitVersions from '../data/kitVersions';

const THIRTY_DAYS = 2592000;

const testRulesChangedDate = DateTime.fromISO('2022-02-11T04:00');

export function transitedCountriesValidator (countries, data) {
  if (countries.length > 5) {
    return {
      isValid: false,
      message: 'Max 5 countries'
    };
  };

  let isValid = true;

  for (const country of countries) {
    isValid = !isCountryExcluded(country, data.arrivalDate);
    if (!isValid) {
      break;
    }
  }

  return {
    isValid,
    message: isValid ? '' : 'As per government guidelines, we cannot issue a certificate if you travelled to this country within 10 days of arrival into the UK'
  };
};

export function email (email) {
  // W3C html5 email regex
  const isValid = regex.email.test(email);

  return {
    isValid,
    message: isValid ? '' : 'Invalid email format'
  };
};

export function repeatEmail (repeatEmail, data) {
  const validatorResult = email(repeatEmail);

  if (!validatorResult.isValid) {
    return validatorResult;
  }

  if (repeatEmail !== data.email) {
    return {
      isValid: false,
      message: 'Emails do not match'
    };
  }

  return {
    isValid: true,
    message: ''
  };
};

export function testDate (date) {
  if (!(date instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const isValid = date.isValid && date.diffNow().milliseconds < 0;
  return {
    isValid,
    message: isValid ? '' : 'Date must be in the past'
  };
}

export function departureDate (date) {
  if (!(date instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const isValid = date.isValid && (date.diffNow().milliseconds > 0 || date.day === DateTime.local().day);
  return {
    isValid,
    message: isValid ? '' : 'Date must be in the future, ensure format dd/MM/yyyy HH:mm'
  };
}

export function dateOfBirth (date) {
  if (!(date instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy'
    };
  }
  const isValid = date.isValid && date.diffNow().milliseconds < 0 && date.diffNow('years').years > -110;

  return {
    isValid,
    message: isValid ? '' : 'Invalid date, ensure format dd/MM/yyyy'
  };
}

export function notBlank (value) {
  const isValid = !!value && (value.trim() !== '');

  return {
    isValid,
    message: isValid ? '' : 'Field cannot be blank'
  };
};

export function checkboxChecked (value) {
  return {
    isValid: value === true,
    message: value === true ? '' : 'Required'
  };
};

export function covidSymptoms (value) {
  const isValid = value === false;

  return {
    isValid,
    message: isValid ? '' : 'If you have had symptoms of COVID-19 in the past 2 weeks, we will not be able to issue a fit to fly certificate, even if your test result is negative.'
  };
};

export function reasonNoFly (value) {
  const isValid = value === false;

  return {
    isValid,
    message: isValid ? '' : 'If you have a medical reason why you should not fly, we will not be able to issue a fit to fly certificate, even if your test result is negative.'
  };
};

export function phoneNumber (phoneNumber) {
  const isUkNumber = phoneNumber.startsWith('+44') || phoneNumber.startsWith('44');
  const regex = isUkNumber ? /^\+?[0-9]{11,12}$/ : /^\+?[0-9]{7,15}$/;

  const isValid = regex.test(phoneNumber);

  return {
    isValid,
    message: isValid ? '' : 'Invalid phone number'
  };
};

export function departureCountry (country, data) {
  if (data.formType === 'booking' && ['day2', 'day2lfd', 'day8', 'testToRelease'].includes(data.certificateType)) {
    const isValid = country.length > 0 && !isCountryExcluded(country, data.dateOfArrival);

    return {
      isValid,
      message: isValid ? '' : 'We cannot issue a certificate if you travelled from this country.'
    };
  }
  else if (data.formType === 'booking') {
    return {
      isValid: true,
      message: ''
    };
  }

  const isValid = country.length > 0 && !isCountryExcluded(country, data.arrivalDate);
  return {
    isValid,
    message: isValid ? '' : 'We cannot issue a certificate if you travelled from this country.'
  };
};

export function destinationCountry (country, data) {
  if (data.formType === 'booking' && ['fitToFly', 'preDepartureCheck'].includes(data.certificateType)) {
    return notBlank(country);
  }
  else if (data.formType === 'booking') {
    return {
      isValid: true,
      message: ''
    };
  }

  return notBlank(country);
}

function isCountryExcluded (country, arrivalDate) {
  const excludedCountry = excludedCountries.find(c => c.name === country);

  if (!excludedCountry) {
    return false;
  }
  if (!arrivalDate) {
    // Validation across 2 fields
    // This works fine when they fill in arrivalDate before departureCountry
    // Rather than trying to account for the case where they haven't filled in arrivalDate first
    // Will allow this case to be caught onSubmit
    return false;
  }

  if (arrivalDate < DateTime.fromISO(excludedCountry.fromDate) || arrivalDate > DateTime.fromISO(excludedCountry.toDate)) {
    return false;
  }
  return true;
}

export function passengerLocatorReference (reference) {
  const trimmed = reference.trim();

  const enteredBookingReference = !!trimmed.match(regex.bookingReference);
  const isValid = trimmed.length === 0 ||
    (trimmed.match(regex.passengerLocatorReference) &&
    trimmed !== 'UKVI_9ZZZ999999999');

  const message = isValid
    ? ''
    : enteredBookingReference
      ? 'Your passenger locator reference is the reference given to you by the UK government Passenger Locator Form before you travel, beginning “UKVI_”, NOT the booking reference (beginning “MSPOT” or “ODLTD”) that we issued you with.'
      : 'Your passenger locator reference is the reference given to you by the UK government Passenger Locator Form before you travel, with the format “UKVI_9ZZZ999999999” (UKVI, followed by an underscore, followed by 13 alphanumeric digits)';

  return {
    isValid,
    message
  };
}

export function msOrderNumber (msOrderNumber, data) {
  const isValid = msOrderNumber.length === 0 || regex.newOrderNumber.test(msOrderNumber);

  return {
    isValid,
    message: isValid ? '' : 'Invalid order number. If you don’t have an order number of the format ‘ON-XXXXX’, you can leave this blank.'
  };
}

// @material-ui/pickers passes luxon DateTime
export function nonExemptCountryDate (dt, data) {
  if (!(dt instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const now = DateTime.local();

  if (!dt.isValid) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const diff = dt.startOf('day').diff(now.startOf('day'), 'days');

  // this is at least day 5 after departure
  if (['testToRelease'].includes(data.selectedCert) && diff.values.days > -5) {
    return {
      isValid: false,
      message: 'You can only perform and register the swab on or after the fifth full day of your departure from a country not on the travel corridors list.'
    };
  }

  return {
    isValid: true,
    message: ''
  };
}

export function bookingReference (bookingReference) {
  const validFormat = regex.bookingReference.test(bookingReference);
  return {
    isValid: validFormat,
    message: validFormat ? '' : 'Invalid booking reference. Your Booking Reference number is either “MSPOT” or “ODLTD” followed by 7 digits.'
  };
};

export function arrivalDate (dt, data) {
  if (!(dt instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const now = DateTime.local();

  if (!dt.isValid) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const diff = dt.diff(now, 'seconds');

  if (diff.values.seconds > 0) {
    return {
      isValid: false,
      message: 'You can only perform and register the swab after your arrival into the country. This date cannot be in the future.'
    };
  }
  else if (diff.values.seconds < -THIRTY_DAYS) {
    return {
      isValid: false,
      message: 'Your arrival date must be within the last 30 days.'
    };
  }
  else if (data.nonExemptCountryDate >= dt) {
    return {
      isValid: false,
      message: 'Your arrival date must be later than your departure date. Both times should be UK time.'
    };
  }
  else if (['day2lfd', 'day8', 'testToRelease'].includes(data.selectedCert) && dt > testRulesChangedDate) {
    return {
      isValid: false,
      message: <>This test is no longer required for arrivals after 04:00 on 11/02/22. Please see the <Link href="https://www.gov.uk/government/news/uk-open-for-travel-with-all-restrictions-removed-for-eligible-vaccinated-arrivals" target="_blank">latest government guidance</Link></>
    };
  }

  return {
    isValid: true,
    message: ''
  };
};

export function dateOfArrival (dt, data) {
  if (!(dt instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  if (['day2', 'day2lfd', 'day8', 'testToRelease'].includes(data.certificateType) && !dt.isValid) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  if (['day2lfd', 'day8', 'testToRelease'].includes(data.certificateType) && dt > testRulesChangedDate) {
    return {
      isValid: false,
      message: <>This test is no longer required for arrivals after 04:00 on 11/02/22. Please see the <Link href="https://www.gov.uk/government/news/uk-open-for-travel-with-all-restrictions-removed-for-eligible-vaccinated-arrivals" target="_blank">latest government guidance</Link></>
    };
  }

  return {
    isValid: true,
    message: ''
  };
};

export function nhsNumber (nhsNumber) {
  const isValid = nhsNumber.length === 0 || nhsNumber.length === 10;

  return {
    isValid,
    message: isValid ? '' : 'Your NHS number should be a 10-digit number'
  };
}

// SBS have a max length of 50 for this field
// IATA states all passport numbers should be no more than 9 in length
// Could restrict to that - but couldn't guarantee every country follows this
export function passportNumber (passportNumber) {
  const isValid = passportNumber.length !== 0 && (passportNumber.trim() !== '') && passportNumber.length <= 50;

  return {
    isValid,
    message: isValid
      ? ''
      : passportNumber !== ''
        ? 'Your passport number should be no more that 50 characters in length'
        : 'Field cannot be blank'
  };
}

// @material-ui/pickers passes luxon DateTime
export function timeOfSwab (dt) {
  if (!(dt instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const now = DateTime.local();

  if (!dt.isValid) {
    return {
      isValid: false,
      message: 'Invalid date, ensure format dd/MM/yyyy HH:mm'
    };
  }

  const diff = dt.diff(now, 'days');

  // if more than a week ago
  if (diff.values.days < -7) {
    return {
      isValid: false,
      message: 'Are you sure this is when you took your swab as this is more than one week ago?'
    };
  }

  // if more than 3 days in the future
  if (diff.values.days > 3) {
    return {
      isValid: false,
      message: 'You can only register your kit 3 days in advance.'
    };
  }

  return {
    isValid: true,
    message: ''
  };
};

export function orderReference (orderReference, data) {
  // if (kitVersions.findByBoxCode(data.boxCode)?.hasShopifyOrderNumber) {
  //   if (orderReference) {
  //     const isValid = regex.newOrderNumber.test(orderReference);
  //     if (isValid) {
  //       return {
  //         isValid: true,
  //         message: ''
  //       };
  //     }
  //     else {
  //       return {
  //         isValid: false,
  //         message: 'Field cannot be blank'
  //       };
  //     }
  //   }
  //   else {
  return {
    isValid: true,
    message: ''
  };
  //   }
  // }
};

export function minMinutesAgo (date, minutes) {
  if (!(date instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date'
    };
  }

  const now = DateTime.local();
  const isValid = date.plus({minutes}) <= now &&
    date <= now;

  return {
    isValid,
    message: isValid ? '' : `Date and time must be over ${minutes}min ago`
  };
};

export function maxMinutesAgo (date, minutes) {
  if (!(date instanceof DateTime)) {
    return {
      isValid: false,
      message: 'Invalid date'
    };
  }

  const now = DateTime.local();
  const isValid = date.plus({minutes}) >= now &&
    date <= now;

  return {
    isValid,
    message: isValid ? '' : `Date and time must be under ${minutes}min ago`
  };
};

export function isolatingCountry (isolatingCountry) {
  const isValid = isolatingCountry === 'United Kingdom';

  return {
    isValid,
    message: isValid ? '' : 'You must provide an address in the United Kingdom'
  };
}

export function vaccinated (vaccinated) {
  if (!vaccinated) {
    return {
      isValid: false,
      message: 'Field cannot be blank'
    };
  }

  return {
    isValid: true,
    message: ''
  };
}

export function day (day, data) {
  const {month, year} = data;
  const maxDay = daysInMonth(month, year);

  if (day < 1) {
    return {
      isValid: false,
      message: 'Day cannot be less than 1'
    };
  }

  if (day > maxDay) {
    return {
      isValid: false,
      message: `Day cannot be greater than ${maxDay}`
    };
  }

  return {
    isValid: true,
    message: ''
  };
}

export function month (month, data) {
  const {day, year} = data;
  const maxDay = daysInMonth(month, year);

  if (month < 1) {
    return {
      isValid: false,
      message: 'Month cannot be less than 1'
    };
  }

  if (month > 12) {
    return {
      isValid: false,
      message: 'Month cannot be greater than 12'
    };
  }

  if (day && day > maxDay) {
    return {
      isValid: false,
      message: `Day cannot be greater than ${maxDay} for this month`
    };
  }

  return {
    isValid: true,
    message: ''
  };
}

export function year (year) {
  if (year < 0) {
    return {
      isValid: false,
      message: 'Year cannot be negative'
    };
  }

  return {
    isValid: true,
    message: ''
  };
}

export const byteSignature = (signature, buffer) => {
  if (!buffer || buffer.length < signature.length) {
    return {
      isValid: false,
      message: 'Insufficient bytes'
    };
  }

  let validSignature = true;
  signature.forEach((el, index) => {
    validSignature &= (el === buffer[index]);
  });

  return {
    isValid: !!validSignature,
    message: validSignature ? '' : 'Invalid signature'
  };
};

export async function image (file) {
  try {
    const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10];
    const jpegSignature = [255, 216, 255];

    const buffer = await readFileAsArrayBuffer(file);
    const uint8Array = new Uint8Array(buffer);
    const validJpeg = ['image/jpeg', 'image/jpg'].includes(file.type) && byteSignature(jpegSignature, uint8Array).isValid;
    const validPng = ['image/png'].includes(file.type) && byteSignature(pngSignature, uint8Array).isValid;

    if ((!validJpeg && !validPng)) {
      throw Error('Image must be of type JPEG or PNG ');
    }

    if (file.size > 5000000) {
      throw Error('Image must be less than 5 MB');
    }

    return {
      isValid: true,
      message: ''
    };
  }
  catch (e) {
    return {
      isValid: false,
      message: e.message ?? 'Image must be of type JPEG or PNG and less than 5 MB'
    };
  }
}

export function rmTrackingNumber (rmTrackingNumber) {
  const isValid = regex.rmTrackingNumber.test(rmTrackingNumber);

  return {
    isValid,
    message: isValid ? '' : 'Invalid tracking number'
  };
}

export function dxTrackingNumber (dxTrackingNumber) {
  const isValid = regex.dxTrackingNumber.test(dxTrackingNumber);

  return {
    isValid,
    message: isValid ? '' : 'Invalid tracking number'
  };
}

export {
  notBlank as flightCoachNumber,
  checkboxChecked as checkedIdentity,
  checkboxChecked as checkedImages,
  checkboxChecked as checkedTime,
  notBlank as selfReviewResult,
  notBlank as address1,
  notBlank as city,
  notBlank as country,
  notBlank as departureCity,
  notBlank as ethnicity,
  notBlank as firstName,
  notBlank as gender,
  notBlank as isolatingAddress1,
  notBlank as isolatingCity,
  notBlank as lastName,
  notBlank as residingCountry,
  notBlank as passportIssuer,
  notBlank as transportType,
  notBlank as whyNeedTest,
  notBlank as isolatingPostcode,
  notBlank as postcode,
  notBlank as testType,
  notBlank as certificateType,
  transitedCountriesValidator as transitedCountries
};
