import {
  Box,
  Button,
  Typography
} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {Bold} from '../utils/commonComponents';

const BookingSuccess = () => {
  const {bookingReference} = useParams();
  return (
    <>
      <Box>
        <Box mb={3}>
          <Bold variant="h5" component="h1" gutterBottom>Thank you for booking</Bold>
        </Box>
        {bookingReference
          ? (
            <>
              <Box mb={2}>
                <Typography component="p" variant="body2" gutterBottom>
                  Your Booking Reference number is <strong style={{fontFamily: 'monospace'}} data-cy='booking-ref'>{bookingReference}</strong>
                </Typography>
                <Typography component="p" variant="body2" gutterBottom>
                  Please write this down.
                </Typography>
                <Typography component="p" variant="body2" gutterBottom>
                  We have also sent this to the email address you provided.
                </Typography>
              </Box>
            </>
          )
          : (
            <Box mb={2}>
              <Typography component="p" variant="body2">
                We have sent your booking reference to the email address you provided.
              </Typography>
            </Box>
          )
        }
        <Box mb={2}>
          <Typography component="p" variant="body2">
            When you are ready to use your test kit, please remember to visit <a href="/register/code" target="_blank">the test kit registration form</a> to register it with us.
          </Typography>
        </Box>

        <Box mt={4}>
          <Box mb={2}>
            <Typography component="p" variant="body2" gutterBottom>
                If you wish to create another booking:
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-around">
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              href="/register/booking"
              target="_blank"
            >
                        Create a new booking
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingSuccess;
