import {
  Box,
  Typography,
  Button
} from '@mui/material';
import React from 'react';
import useHubspot from '../../services/useHubspot';
import InfoBox from '../InfoBox';
import StepLayout from '../StepLayout';

const PCRSubmission = ({
  registrationData
}) => {
  useHubspot();

  return (
    <StepLayout
      title="Return your test to the lab"
      header={
        <InfoBox
          message="Your test kit is now registered"
          level="success"
        />
      }
    >
      <Box mb={3}>
        <Box mb={2.5}>
          <Typography variant="body1" gutterBottom component="p" >
            Please follow the instructions in the test kit and and return the test.
          </Typography>
        </Box>
        <Box mb={2.5}>
          <Typography component="p" variant="h3" gutterBottom>
            Your certificate
          </Typography>
          <br/>
          <Typography variant="body1" gutterBottom component="p">
            You will get your certificate latest <strong>24 hours after the lab receive your test.</strong>
            <br/><br/>
            We will send your certificate to this email address: <strong>{registrationData.email}</strong>.
            <br/><br/>
            If you have any questions, please see the FAQ or contact us.
          </Typography>
        </Box>
        <Box borderBottom={'solid 1px rgba(0, 0, 0, 0.12)'} my={3}/>
        <Box mt={4}>
          <Box mb={2}>
            <Typography component="p" variant="h3" gutterBottom>
              Do you have more tests to register?
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              href="/register/code"
              target="_blank"
            >
              Register a new kit code
            </Button>
          </Box>
        </Box>
      </Box>
    </StepLayout>
  );
};

export default PCRSubmission;
