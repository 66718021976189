import {
  Box,
  Autocomplete
} from '@mui/material';
import React, {useState} from 'react';
import countries from '../data/countries';
import TextField from './TextField';

const CountrySelect = ({
  label,
  value,
  name,
  error,
  required = false,
  onChange,
  helperText,
  'data-cy': dataCy,
  disabled
}) => {
  const [csValue, setCsValue] = useState(countries.find(element => element.name === value) ?? null);

  return (
    <Box my={2}>
      <Autocomplete
        options={countries}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={name}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password' // disable autocomplete and autofill
            }}
            error={error}
            helperText={helperText}
            required={required}
            disabled={disabled}
          />
        )}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value?.name }
        value={csValue}
        onChange={(_event, newValue) => {
          setCsValue(newValue);
          onChange(newValue?.name ?? '');
        }}
        data-cy={dataCy}
      />
    </Box>
  );
};

export default CountrySelect;
