import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import {TextField, Box} from '@mui/material';
import React from 'react';

const DateTime = ({
  error,
  label,
  onChange,
  onBlur,
  value,
  required,
  helperText,
  'data-cy': dataCy
}) => {
  // @material-ui/pickers requires null value for clearable
  const pickerValue = value === '' ? null : value;

  return (
    <Box mb={2}>
      <DesktopDateTimePicker
        ampm={false}
        label={label}
        clearable={true}
        inputVariant="outlined"
        value={pickerValue}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        inputFormat="dd/MM/yyyy HH:mm"
        renderInput={(params) => <TextField fullWidth {...params} error={!!error} helperText={error ?? helperText} data-cy={dataCy}/>}
      />
    </Box>
  );
};

export default DateTime;
