import {
  Button,
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Typography,
  Alert
} from '@mui/material';
import {
  isFunction,
  omit,
  pick
} from 'lodash';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  useSearchParams,
  useNavigate
} from 'react-router-dom';
import ethnicities from '../data/ethnicities';
import {genderOptions} from '../data/genderOptions';
import {vaccinatedOptions} from '../data/vaccinatedOptions';
import ga from '../services/google_analytics';
import {Bold} from '../utils/commonComponents';
import * as formatters from '../utils/formatters';
import regex from '../utils/regex';
import sentry from '../utils/sentry';
import * as validators from '../utils/validators';
import CountrySelect from './CountrySelect';
import DatePicker from './DatePicker';
import DateTime from './DateTime';
import InfoBox from './InfoBox';
import PhoneNumberField from './PhoneNumberField';
import Select from './Select';
import SubmissionConfirmation from './SubmissionConfirmation.js';
import TextField from './TextField';

const pickValidators = (vds, additionalFields = {}) => {
  return pick(vds, [...aboutYouDefaults.map(a => a.name), ...contactDefaults.map(a => a.name), ...testDefaults.map(a => a.name), ...Object.keys(additionalFields)]);
};

const aboutYouDefaults = [
  {
    displayName: 'Order Number',
    name: 'msOrderNumber',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'firstName',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'middleName',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'lastName',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'dateOfBirth',
    type: 'date',
    format: 'dd/MM/yyyy',
    default: '',
    displayInSummary: true
  },
  {
    name: 'gender',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'vaccinated',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'ethnicity',
    type: 'string',
    default: '',
    displayInSummary: true
  }
];

const contactDefaults = [
  {
    name: 'email',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'repeatEmail',
    type: 'string',
    default: '',
    displayInSummary: false
  },
  {
    name: 'phoneNumber',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'address1',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'address2',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'city',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'postcode',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'country',
    type: 'string',
    default: '',
    displayInSummary: true
  }
];

const testDefaults = [
  {
    name: 'formType',
    type: 'string',
    default: 'booking',
    displayInSummary: false
  },
  {
    name: 'testType',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'certificateType',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'destinationCountry',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    name: 'departureCountry',
    type: 'string',
    default: '',
    displayInSummary: true
  },
  {
    displayName: 'Arrival date in the UK',
    name: 'dateOfArrival',
    type: 'date',
    format: 'dd/MM/yyyy',
    default: '',
    displayInSummary: true
  }
];

const Booking = (props) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({
    ...Object.fromEntries(aboutYouDefaults.map(a => [a.name, a.default])),
    ...Object.fromEntries(contactDefaults.map(a => [a.name, a.default])),
    ...Object.fromEntries(testDefaults.map(a => [a.name, a.default]))
  });

  const [showSubmissionConfirmation, setShowSubmissionConfirmation] = useState(false);

  ga.event({
    category: 'F2FBookingForm',
    action: 'BOOKING_FORM_REACHED',
    nonInteraction: true
  });

  // create a ref for stale closures
  const dataRef = useRef(data);
  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    const onUnload = function (event) {
      event.preventDefault();
      event.returnValue = 'Any unsaved progress will be lost?';
    };

    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  useEffect(() => {
    const orderNumber = searchParams.get('on');
    async function getAndSetOrderNumber () {
      if (regex.newOrderNumber.test(orderNumber)) {
        setData(d => ({
          ...d,
          msOrderNumber: orderNumber
        }));
      }
    }
    if (orderNumber) {
      getAndSetOrderNumber();
    }
  }, [searchParams]);

  const handleChange = (field, opts = {setInvalidValues: true}) => async eventData => {
    // if no target it's not an event, and so assume it's a value
    const value = eventData?.target ? eventData.target.value : eventData;

    const update = {
      [field]: value ?? ''
    };
    const isValid = updateFieldValidation(field, eventData);

    // if setInvalidValues is true then always set data.
    // if setInvalidValues is false and the data is valid then set data.
    if (opts.setInvalidValues || (!opts.setInvalidValues && isValid)) {
      setData(current => ({...current, ...update}));
    }
  };

  const validateInputs = () => {
    const selectedValidators = pickValidators(validators);
    let inputsAreValid = true;

    for (const key of Object.keys(data)) {
      const validator = selectedValidators[key];
      const formatter = formatters[key];

      // if there is a formatter, format the value and write back to data
      if (isFunction(formatter)) {
        data[key] = formatter(data[key]);
      }

      if (isFunction(validator)) {
        const {isValid, message} = validator(data[key], {...data});
        inputsAreValid = inputsAreValid && isValid;

        if (!isValid) {
          setErrors(current => ({
            ...current,
            [key]: message
          }));
        }
      }
    }

    return inputsAreValid;
  };

  const trim = (field) => () => {
    const update = {
      [field]: data[field].trim()
    };
    setData(current => ({...current, ...update}));
  };

  const handleSubmit = async () => {
    // submit form data if fields are valid
    // else show form entry fields
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      try {
        const res = await fetch(
          `${process.env.REACT_API_URL}/booking/form-submit`,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ...data,
              email: data.email.toLowerCase(),
              repeatEmail: data.email.toLowerCase()
            })
          }
        );

        if (res.ok) {
          const json = await res.json();
          ga.event({
            category: 'F2FBookingForm',
            action: 'BOOKING_FORM_SUCCESS',
            nonInteraction: true
          });

          navigate(`./success/${json.bookingReference}`);
        }
        else if (res.status === 401) {
          return res.json();
        }
        else {
          setSubmissionError('Submission failed.');
          ga.event({
            category: 'F2FBookingForm',
            action: 'BOOKING_FORM_ERROR',
            nonInteraction: true
          });
          setIsSubmitting(false);
        }
      }
      catch (e) {
        setIsSubmitting(false);
        sentry.captureException(e);
      }
    }
    else {
      setShowSubmissionConfirmation(false);
    }
  };

  const updateFieldValidation = (field, eventData) => {
    const fieldValidator = validators?.[field];
    const target = eventData?.target;
    const value = target ? target.value : eventData;

    if (isFunction(fieldValidator)) {
      const {isValid, message} = fieldValidator(value, dataRef.current);

      if (!isValid) {
        setErrors(current => ({
          ...current,
          [field]: message
        }));
      }
      else {
        setErrors(omit(errors, field));
      }

      return isValid;
    }
  };

  const completeFormEntry = () => {
    const canShowSubmission = validateInputs();

    if (canShowSubmission) {
      setShowSubmissionConfirmation(true);
    }
  };

  const showBookingForm = process.env.BOOKING_FORM_ENABLED === 'true';

  return (
    <>
      {showSubmissionConfirmation
        ? (
          <SubmissionConfirmation
            formData={data}
            fields={[aboutYouDefaults, contactDefaults, testDefaults]}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onBackNav={() => setShowSubmissionConfirmation(false)}
          />
        )
        : (
          <Box>
            {showBookingForm
              ? (
                <>
                  <Box mb={3}>
                    <Bold variant="h5" component="h1" gutterBottom>Complete this Booking Form to get a Booking Reference number</Bold>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="body2" gutterBottom>
                      You must complete this form for each test kit you have purchased. You will be emailed a unique Booking Reference number for each of them as soon as possible.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      You will then need to register your test kit when you are ready to take the test.
                    </Typography>
                  </Box>
                  <InfoBox
                    message='From Friday 18th March 4am, all travellers arriving to the UK after this time will not need to complete this form or take any Covid Tests.'
                    level='infoLight'
                    medicspotLink = 'https://tests.medicspot.co.uk/blogs/news/updates-to-the-covid-requirements-for-travellers-entering-the-uk'
                    outcomeDxLink = 'https://www.gov.uk/guidance/travel-to-england-from-another-country-during-coronavirus-covid-19'
                  />
                  <Box marginBottom="20px">
                    <Paper variant="outlined">
                      <Box padding="20px">
                        <Box mb={2}>
                          <Typography variant="subtitle2">About you</Typography>
                        </Box>
                        {data.msOrderNumber?.length > 0 &&
                        <TextField
                          label="Order number"
                          name="msOrderNumber"
                          value={data.msOrderNumber}
                          error={errors.msOrderNumber}
                          onChange={handleChange('msOrderNumber')}
                          placeholder='e.g. ON-CD124'
                          hidden
                        />
                        }
                        <TextField
                          label="First name"
                          name="firstName"
                          value={data.firstName}
                          error={errors.firstName}
                          onChange={handleChange('firstName')}
                          onBlur={trim('firstName')}
                          inputProps={{maxLength: 50}}
                          required
                          data-cy="first-name-input"
                        />
                        <TextField
                          label="Middle name(s)"
                          name="middleName"
                          value={data.middleName}
                          error={errors.middleName}
                          onChange={handleChange('middleName')}
                          onBlur={trim('middleName')}
                          inputProps={{maxLength: 50}}
                          data-cy="middle-name-input"
                        />
                        <TextField
                          label="Last name"
                          name="lastName"
                          value={data.lastName}
                          error={errors.lastName}
                          onChange={handleChange('lastName')}
                          onBlur={trim('lastName')}
                          inputProps={{maxLength: 50}}
                          required
                          data-cy="last-name-input"
                        />
                        <DatePicker
                          label="Date of birth"
                          name="dateOfBirth"
                          value={data.dateOfBirth}
                          error={errors.dateOfBirth}
                          disableFuture={true}
                          onChange={handleChange('dateOfBirth')}
                          required
                          data-cy="dob-picker"
                        />
                        <Select
                          label="Your sex assigned at birth"
                          name="gender"
                          value={data.gender}
                          error={errors.gender}
                          onChange={handleChange('gender')}
                          required
                          options={genderOptions}
                          data-cy="gender-select"
                        />
                        <Select
                          label="Vaccination status against COVID-19"
                          name="vaccinated"
                          value={data.vaccinated}
                          error={errors.vaccinated}
                          onChange={handleChange('vaccinated')}
                          required
                          options={vaccinatedOptions}
                          data-cy="vaccination-select"
                        />
                        <Select
                          label="Ethnicity"
                          name="ethnicity"
                          value={data.ethnicity}
                          error={errors.ethnicity}
                          onChange={handleChange('ethnicity')}
                          required
                          options={ethnicities}
                          data-cy="ethnicity-select"
                        />
                      </Box>
                    </Paper>
                  </Box>
                  <Box marginBottom="20px">
                    <Paper variant="outlined">
                      <Box padding="20px">
                        <Box mb={2}>
                          <Typography variant="subtitle2">Contact details</Typography>
                        </Box>
                        <TextField
                          label="Email"
                          name="email"
                          value={data.email}
                          error={errors.email}
                          onChange={handleChange('email')}
                          onBlur={trim('email')}
                          required
                          data-cy="email-input"
                        />
                        <TextField
                          label="Repeat email"
                          name="email"
                          value={data.repeatEmail}
                          error={errors.repeatEmail}
                          onChange={handleChange('repeatEmail')}
                          onBlur={trim('repeatEmail')}
                          required
                          data-cy="repeat-email-input"
                        />
                        <PhoneNumberField
                          label="Phone number"
                          name="phoneNumber"
                          value={data.phoneNumber}
                          error={errors.phoneNumber}
                          onChange={handleChange('phoneNumber')}
                          defaultCountry="gb"
                          inputProps={{
                            autoComplete: 'new-password',
                            form: {
                              autocomplete: 'off'
                            }
                          }}
                          onBlur={trim('phoneNumber')}
                          required
                          data-cy="phone-number-input"
                        />
                        <TextField
                          label="Address 1"
                          name="address1"
                          value={data.address1}
                          error={errors.address1}
                          onChange={handleChange('address1')}
                          onBlur={trim('address1')}
                          required
                          data-cy="address1-input"
                        />
                        <TextField
                          label="Address 2"
                          name="address2"
                          value={data.address2}
                          error={errors.address2}
                          onChange={handleChange('address2')}
                          onBlur={trim('address2')}
                          data-cy="address2-input"
                        />
                        <TextField
                          label="City"
                          name="city"
                          value={data.city}
                          error={errors.city}
                          onChange={handleChange('city')}
                          onBlur={trim('city')}
                          required
                          data-cy="city-input"
                        />
                        <TextField
                          label="Postcode"
                          name="postcode"
                          value={data.postcode}
                          error={errors.postcode}
                          onChange={handleChange('postcode')}
                          inputProps={{maxLength: 15}}
                          onBlur={trim('postcode')}
                          required
                          data-cy="postcode-input"
                        />
                        <CountrySelect
                          label="Country"
                          name="country"
                          value={data.country}
                          error={errors.country}
                          onChange={handleChange('country')}
                          required
                          data-cy="country-select"
                        />
                      </Box>
                    </Paper>
                  </Box>

                  <Box marginBottom="20px">
                    <Paper variant="outlined">
                      <Box padding="20px">
                        <Box mb={2}>
                          <Typography variant="subtitle2">About the test you are registering</Typography>
                        </Box>
                        <TextField
                          select
                          label="Test type"
                          name="testType"
                          value={data.testType}
                          error={errors.testType}
                          onChange={handleChange('testType')}
                          required
                          data-cy="test-type-select"
                        >
                          <MenuItem aria-label="None" value="">Select</MenuItem>
                          <MenuItem key='lfd' value='LFD'>Lateral Flow (Rapid Antigen)</MenuItem>
                          <MenuItem key='pcr' value='PCR'>PCR</MenuItem>
                        </TextField>
                        {data.testType &&
                        <TextField
                          select
                          label="Purpose of test"
                          name="certificateType"
                          value={data.certificateType}
                          error={errors.certificateType}
                          onChange={handleChange('certificateType')}
                          required
                          data-cy="cert-type-select"
                        >
                          <MenuItem aria-label="None" value="">Select</MenuItem>
                          {data.testType === 'LFD' && <MenuItem key='preDepartureCheck' value='preDepartureCheck'>Fit to fly</MenuItem>}
                          {data.testType === 'LFD' && <MenuItem key='day2lfd' value='day2lfd'>Day 2</MenuItem>}
                          {data.testType === 'PCR' && <MenuItem key='fitToFly' value='fitToFly'>Fit to fly</MenuItem>}
                          {data.testType === 'PCR' && <MenuItem key='day2' value='day2'>Day 2</MenuItem>}
                          {data.testType === 'PCR' && <MenuItem key='day8' value='day8'>Day 8</MenuItem>}
                          {data.testType === 'PCR' && <MenuItem key='testToRelease' value='testToRelease'>Test to Release (Day 5)</MenuItem>}
                          {data.testType === 'PCR' && <MenuItem key='basic' value='basic'>Any other reason</MenuItem>}
                        </TextField>
                        }
                        {data.testType && ['fitToFly', 'preDepartureCheck'].includes(data.certificateType) &&
                        <CountrySelect
                          label="What country are you travelling to?"
                          name="country"
                          value={data.destinationCountry}
                          error={errors.destinationCountry}
                          onChange={handleChange('destinationCountry')}
                          required
                          data-cy="destination-country-select"
                        /> }
                        {data.testType && ['day2', 'day2lfd', 'day8', 'testToRelease'].includes(data.certificateType) &&
                        <CountrySelect
                          label="What country will you be travelling from when you return to the UK?"
                          name="country"
                          value={data.departureCountry}
                          error={errors.departureCountry}
                          onChange={handleChange('departureCountry')}
                          required
                          data-cy="departure-country-select"
                        />
                        }
                        {data.testType && ['day2', 'day2lfd', 'day8', 'testToRelease'].includes(data.certificateType) &&
                        <DateTime
                          label="Date of arrival into UK"
                          value={data.dateOfArrival}
                          error={errors.dateOfArrival}
                          onChange={handleChange('dateOfArrival')}
                          required
                          data-cy='arrival-date-picker'
                        />
                        }
                      </Box>
                    </Paper>
                  </Box>

                  {Object.keys(errors).length > 0 && (
                    <Box display="flex" justifyContent="center" marginTop="30px">
                      <Alert severity="error">Form contains errors</Alert>
                    </Box>
                  )}

                  <Box display="flex" justifyContent="center" marginTop="30px">
                    {
                      isSubmitting
                        ? (
                          <CircularProgress />
                        )
                        : (
                          <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            disabled={Object.keys(errors).length !== 0}
                            onClick={completeFormEntry}
                            data-cy="next-button"
                          >
                          Next
                          </Button>
                        )
                    }
                  </Box>

                  <Box display="flex" justifyContent="center" marginTop="20px">
                    {submissionError && (
                      <Alert severity="error">{submissionError}</Alert>
                    )}
                  </Box>
                </>)

              : (
                <>
                  <Box mb={3}>
                    <Bold variant="h5" component="h1" gutterBottom>Booking form</Bold>
                  </Box>
                  <Box mb={3}>
                    <Typography variant="body1">
                      From Friday 18th March 4am, all travellers arriving to the UK after this time will not need to complete the booking form or take any Covid Tests.
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      href="https://www.gov.uk/guidance/travel-to-england-from-another-country-during-coronavirus-covid-19"
                      target="_blank"
                    >
                      Find out more
                    </Button>
                  </Box>
                  <Box mb={1}>
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      href="/register/code"
                      target="_blank"
                    >
                      Register test kit
                    </Button>
                  </Box>
                </>
              )}
          </Box>
        )}
    </>
  );
};

export default Booking;
