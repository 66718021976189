import {Box} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {whyNeedTestOptions} from '../../data/whyNeedTestOptions';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import Select from '../Select';
import StepLayout from '../StepLayout';

const ReasonForTest = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {whyNeedTest = ''} = registrationData;
  const [localData, setLocalData] = useState({whyNeedTest});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Reason for Test"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <Select
            label="Why do you need a COVID test?"
            value={localData.whyNeedTest}
            error={errors.whyNeedTest}
            onChange={handleChange('whyNeedTest')}
            required
            options={whyNeedTestOptions}
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default ReasonForTest;
