import {
  Box,
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import CountrySelect from '../CountrySelect';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const IsolatingAddress = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {isolatingAddress1 = '', isolatingAddress2 = '', isolatingCity = '', isolatingPostcode = '', isolatingCountry = 'United Kingdom', isolatingAddressSameAsHomeAddress = registrationData.country === 'United Kingdom'} = registrationData;
  const [localData, setLocalData] = useState({isolatingAddress1, isolatingAddress2, isolatingCity, isolatingPostcode, isolatingCountry, isolatingAddressSameAsHomeAddress});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  const [isolatingAddress, setIsolatingAddress] = useState(isolatingAddressSameAsHomeAddress && registrationData.country === 'United Kingdom' ? 'home' : 'other');

  useEffect(() => {
    if (registrationData?.isolatingAddressSameAsHomeAddress && registrationData.country !== 'United Kingdom') {
      setLocalData((current) => ({
        ...current,
        isolatingAddress1: '',
        isolatingAddress2: '',
        isolatingCity: '',
        isolatingPostcode: '',
        isolatingAddressSameAsHomeAddress: false
      }));
    }
  }, [setLocalData, registrationData]);

  useEffect(() => {
    const {address1, address2, city, postcode, country} = registrationData;
    setErrors({});
    if (isolatingAddress === 'home') {
      setLocalData((current) => ({
        ...current,
        isolatingAddress1: address1,
        isolatingAddress2: address2,
        isolatingCity: city,
        isolatingPostcode: postcode,
        isolatingCountry: country,
        isolatingAddressSameAsHomeAddress: true
      }));
    }
    else {
      setLocalData((current) => ({
        ...current,
        isolatingAddressSameAsHomeAddress: false
      }));
    }
  }, [isolatingAddress, setLocalData, registrationData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Where will you be isolating if your result is positive?"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box mb={2}>
          <FormControl>
            <Typography component="p" variant="body2" gutterBottom>
            Isolating address in United Kingdom
            </Typography>
            <RadioGroup
              value={isolatingAddress}
              onChange={(event) => {
                setIsolatingAddress(event.target.value);
              }}
              name="radio-buttons-group"
            >
              <FormControlLabel disabled={registrationData.country !== 'United Kingdom'} value={'home'} control={<Radio color="primary" />} label="My home address" />
              <FormControlLabel value={'other'} control={<Radio color="primary"/>} label="Other" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <TextField
            label="Isolating Address 1"
            name="isolatingAddress1"
            value={localData.isolatingAddress1}
            onChange={handleChange('isolatingAddress1')}
            error={errors.isolatingAddress1}
            disabled={isolatingAddress === 'home'}
            required
          />
          <TextField
            label="Isolating Address 2"
            name="isolatingAddress2"
            value={localData.isolatingAddress2}
            onChange={handleChange('isolatingAddress2')}
            error={errors.isolatingAddress2}
            disabled={isolatingAddress === 'home'}
          />
          <TextField
            label="Isolating City"
            inputProps={{maxLength: 50}}
            name="isolatingCity"
            value={localData.isolatingCity}
            onChange={handleChange('isolatingCity')}
            error={errors.isolatingCity}
            disabled={isolatingAddress === 'home'}
            required
          />
          <TextField
            label="Isolating Postcode"
            inputProps={{maxLength: 15}}
            name="isolatingPostcode"
            value={localData.isolatingPostcode}
            onChange={handleChange('isolatingPostcode')}
            error={errors.isolatingPostcode}
            disabled={isolatingAddress === 'home'}
            required
          />
          <CountrySelect
            label="Isolating Country"
            name="isolatingCountry"
            value={localData.isolatingCountry}
            error={errors.isolatingCountry}
            onChange={handleChange('isolatingCountry')}
            required
            disabled
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default IsolatingAddress;
