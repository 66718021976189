import {Box, TextField, Alert, Grid} from '@mui/material';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import makeValidate from '../utils/validate';

const DateInput = ({
  error,
  onChange,
  value,
  dataCy
}) => {
  const date = value?.isValid
    ? {
      day: value.get('day'),
      month: value.get('month'),
      year: value.get('year')
    }
    : {day: '', month: '', year: ''};
  const {day, month, year} = date;

  const [data, setData] = useState({day, month, year});
  const [errors, setErrors] = useState({});
  const validate = makeValidate(setErrors, data);

  const showErrorMessage = Object.keys(errors).length || error;

  const handleChange = (field) => (eventData) => {
    const value = eventData.target ? eventData.target.value : event;
    const update = {
      [field]: value
    };
    validate(field, value);
    setData(current => ({...current, ...update}));

    const currentData = {...data, [field]: value};
    const {day, month, year} = currentData;
    const date = DateTime.local(parseInt(year), parseInt(month), parseInt(day));

    if (date.isValid) {
      onChange(date);
    }
  };

  useEffect(() => {
    const {day, month, year} = data;
    if (day && month && year) {
      const validate = makeValidate(setErrors, data);
      validate('day', day);
      validate('month', month);
    }
  }, [data]);

  return (
    <Box my={2}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={4}>
          <TextField
            label="Day"
            onChange={handleChange('day')}
            error={!!errors.day}
            value={data.day}
            placeholder="DD"
            variant="outlined"
            type="number"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{'data-cy': `${dataCy}-day`}}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Month"
            onChange={handleChange('month')}
            error={!!errors.month}
            value={data.month}
            placeholder="MM"
            variant="outlined"
            type="number"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{'data-cy': `${dataCy}-month`}}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            item xs={4}
            label="Year"
            onChange={handleChange('year')}
            error={!!errors.year}
            value={data.year}
            placeholder="YYYY"
            variant="outlined"
            type="number"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{'data-cy': `${dataCy}-year`}}
          />
        </Grid>
      </Grid>
      {showErrorMessage && <Alert severity="error">Invalid date of birth provided</Alert>}
    </Box>
  );
};

DateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(DateTime), PropTypes.string]).isRequired,
  error: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateInput;
