import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@mui/lab';
import {Typography} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import styled from 'styled-components';

const StyledStatus = styled(Typography)`
  line-height: 1;
`;

const StyledTimeline = styled(Timeline)`
  @media only screen and (max-width: 600px) {
    padding-right: 0;
    padding-left: 0;

    .MuiTimelineContent-root {
      padding-left: 0;
    }
    .MuiTimelineOppositeContent-root {
      padding-right: 0;
    }
  }
`;

const StyledTimelineDot = styled(({success, ...rest}) => <TimelineDot {...rest} />)`
  background-color: ${({success}) => success ? '#4caf50' : '#bdbdbd'}
`;

const StyledTimelineSeparator = styled(({success, ...rest}) => <TimelineSeparator {...rest} />)`
  .MuiTimelineConnector-root {
    background-color: ${({success}) => success ? '#4caf50' : '#bdbdbd'}
  }
`;

const stages = [
  'Registered at',
  'Test performed on',
  'Arrived at the lab',
  'Results sent'
];

const formatDate = (date) => {
  const dt = DateTime.fromISO(date);

  if (dt.isValid) {
    return dt.toLocaleString({
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  else {
    return 'N/A';
  }
};

const isSuccess = (index, status) => {
  // all stages success if completed
  if (status.completedAt) {
    return true;
  }

  // first 3 stages if received
  if (status.receivedAt && index < 3) {
    return true;
  }

  // first two stages if only registered
  if (status.registeredAt && index < 2) {
    return true;
  }

  return false;
};

const getInfoText = (currentStage, index, status) => {
  if (index === 0) {
    return <Typography variant="body2">{formatDate(status.registeredAt)}</Typography>;
  }

  if (index === 1) {
    return <Typography variant="body2">{formatDate(status.testDate)}</Typography>;
  }

  if (index === 2) {
    if (currentStage === 'received' || currentStage === 'completed') {
      return (
        <>
          <Typography variant="subtitle2">Lab received successfully</Typography>
          <Typography variant="body2">{formatDate(status.receivedAt)}</Typography>
        </>
      );
    }
    else {
      return <Typography variant="body2">pending</Typography>;
    }
  }

  // if received by the lab but not complete show 'Lab processing order'
  // if completed, show results sent
  // otherwise show nothing
  if (index === 3) {
    if (currentStage === 'received') {
      return <Typography variant="body2">Lab processing order</Typography>;
    }
    else if (currentStage === 'completed') {
      return (
        <>
          <Typography variant="subtitle2">Results sent</Typography>
          <Typography variant="body2">{formatDate(status.completedAt)}</Typography>
        </>
      );
    }
    else {
      return '';
    }
  }
};

const MSTimeline = ({status}) => {
  const currentStage = status.status;

  if (!status) {
    return null;
  }

  return (
    <StyledTimeline align="right">
      {stages.map((stage, index) => {
        const infoText = getInfoText(currentStage, index, status);

        return (
          <TimelineItem key={stage}>
            {infoText && (
              <TimelineOppositeContent>
                {infoText}
              </TimelineOppositeContent>
            )}
            {/* index + 1 to only color previous connector */}
            <StyledTimelineSeparator success={isSuccess(index + 1, status)}>
              <StyledTimelineDot success={isSuccess(index, status)} />
              {index < stages.length - 1 && (
                <TimelineConnector />
              )}
            </StyledTimelineSeparator>
            <TimelineContent>
              <StyledStatus variant="h6" component="h2">{stage}</StyledStatus>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </StyledTimeline>
  );
};

export default MSTimeline;
