import {
  WarningOutlined as WarningIcon,
  InfoOutlined as InfoIcon,
  CheckCircleOutlined as SuccessIcon
} from '@mui/icons-material';
import {
  Box,
  Typography,
  Link
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React from 'react';

// Link colour isn't visible on blue background - if using a link need to update this component
const InfoBox = ({
  message,
  title,
  level,
  enabled = true,
  centreIcon = true,
  medicspotLink,
  outcomeDxLink,
  className // required to get styled-components styling
}) => {
  const theme = useTheme();

  const linkMapping = {
    'testkit.medicspot.localhost': medicspotLink,
    'testkit.dev.medicspot.co.uk': medicspotLink,
    'testkit.medicspot.co.uk': medicspotLink,
    'tests.outcomedx.co.uk': outcomeDxLink
  };

  const link = window.location.host in linkMapping ? linkMapping[window.location.host] : null;

  // If any of these colours are used elsewhere, we should put them into our theme
  const styleMapping = {
    info: {
      bgColor: theme.palette.infoBoxBackground.info,
      textColor: theme.palette.text.contrast,
      icon: <InfoIcon />
    },
    infoLight: {
      bgColor: theme.palette.infoBoxBackground.infoLight,
      textColor: 'primary',
      icon: <InfoIcon />,
      iconColor: '#2196F3'
    },
    warning: { // This warning box should probs be restyled by a designed, colours aren't great
      bgColor: theme.palette.infoBoxBackground.warning,
      textColor: 'primary',
      icon: <WarningIcon />
    },
    success: {
      bgColor: theme.palette.infoBoxBackground.success,
      textColor: theme.palette.text.contrast,
      icon: <SuccessIcon />
    }
  };

  return (
    enabled && (message || title) &&
       (
         <Box
           mb={2}
           className={className}
         >
           <Box
             display="flex"
             padding={2}
             bgcolor={styleMapping[level].bgColor}
             color={styleMapping[level].textColor}
             borderRadius="4px"
           >
             <Box
               display={centreIcon ? 'flex' : 'block'}
               flexDirection="column"
               justifyContent="center"
               color={styleMapping[level].iconColor ?? styleMapping[level].textColor}
             >
               {styleMapping[level].icon}
             </Box>
             <Box
               ml={2}
               display="flex"
               flexDirection="column"
               justifyContent="center"
             >
               {title && <Typography component="h5" variant="h6" gutterBottom>
                 {title}
               </Typography>
               }
               <Typography variant="body2">
                 {message} {link && <Link href={link} target="_blank" rel="noreferrer">Find out more.</Link>}
               </Typography>
             </Box>
           </Box>
         </Box>
       )
  );
};

export default InfoBox;
