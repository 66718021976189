export const getRegistration = async (email, testKitCode) => {
  const apiSearchMatchingRegUrl = `${process.env.REACT_API_URL}/test-kit-registration/search/by-email-and-kit-code?email=${email}&kitCode=${testKitCode}`;
  const res = await fetch(apiSearchMatchingRegUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });

  if (res.ok) {
    const data = await res.json();
    return {
      success: true,
      data
    };
  }

  return {
    success: false,
    data: ''
  };
};
