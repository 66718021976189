import {get, round} from 'lodash';

const pcrCommonPath = [
  {
    stage: 'Register kit',
    steps: [
      {step: 'booking'},
      {step: 'names'},
      {step: 'personalInfo'},
      {step: 'emailAndPhone'},
      {step: 'passport'},
      {step: 'nhsDetails'},
      {step: 'address'},
      {step: 'isolatingAddress'},
      {step: 'returnTravelDetails'},
      {step: 'vaccinationStatus'},
      {step: 'deliveryTrackingNumber'}
    ]
  },
  {
    stage: 'Take test',
    steps: [
      {step: 'swabDetails'} // Keeping separate from testTime in LFD for now - but note we should look at combining these 2 pages/fields
    ]
  },
  {
    stage: 'Send test',
    steps: [
      {step: 'confirmDetails'},
      {step: 'pcrSubmission', hideBack: true}
    ]
  }
];

const antigenSubmissionPath = [
  {
    stage: 'Take test',
    steps: [
      {step: 'nextSteps', hideBack: true},
      {step: 'testTime'}
    ]
  },
  {
    stage: 'Upload result',
    steps: [
      {step: 'waitResult'},
      {step: 'uploadPhoto'},
      {step: 'reviewPhoto'},
      {step: 'selfReviewResult'},
      {step: 'submissionConfirmation'},
      {step: 'lfdSubmission', hideBack: true} // same as PCR but with LFD conditional content
    ]
  }
];

const paths = {
  pcr: {
    basic: [
      {
        stage: 'Register kit',
        steps: [
          {step: 'names'},
          {step: 'personalInfo'},
          {step: 'emailAndPhone'},
          {step: 'address'},
          {step: 'vaccinationStatus'},
          {step: 'deliveryTrackingNumber'},
          {step: 'reasonForTest'}
        ]
      },
      {
        stage: 'Take test',
        steps: [
          {step: 'swabDetails'}
        ]
      },
      {
        stage: 'Send test',
        steps: [
          {step: 'confirmDetails'},
          {step: 'pcrSubmission', hideBack: true}
        ]
      }
    ],
    fitToFly: [
      {
        stage: 'Register kit',
        steps: [
          {step: 'names'},
          {step: 'personalInfo'},
          {step: 'emailAndPhone'},
          {step: 'passport'},
          {step: 'address'},
          {step: 'outboundTravelDetails'},
          {step: 'vaccinationStatus'},
          {step: 'deliveryTrackingNumber'},
          {step: 'symptoms'} // shall we leave out symptoms?
        ]
      },
      {
        stage: 'Take test',
        steps: [
          {step: 'swabDetails'}
        ]
      },
      {
        stage: 'Send test',
        steps: [
          {step: 'confirmDetails'},
          {step: 'pcrSubmission', hideBack: true}
        ]
      }
    ],
    ttr: pcrCommonPath,
    day2: pcrCommonPath,
    day8: pcrCommonPath
  },

  lfd: {
    basic: [
      {
        stage: 'Register kit',
        steps: [
          {step: 'names'},
          {step: 'personalInfo'},
          {step: 'emailAndPhone'},
          {step: 'address'},
          {step: 'vaccinationStatus'},
          {step: 'reasonForTest'},
          {step: 'confirmDetails'}
        ]
      },
      ...antigenSubmissionPath
    ],

    fitToFly: [
      {
        stage: 'Register kit',
        steps: [
          {step: 'names'},
          {step: 'personalInfo'},
          {step: 'emailAndPhone'},
          {step: 'passport'},
          {step: 'address'},
          {step: 'outboundTravelDetails'},
          {step: 'vaccinationStatus'}, // This can include symptoms as well ?
          {step: 'symptoms'}, // shall we leave out symptoms?
          {step: 'confirmDetails'}
        ]
      },
      ...antigenSubmissionPath
    ],

    day2: [
      {
        stage: 'Register kit',
        steps: [
          {step: 'booking'},
          {step: 'names'},
          {step: 'personalInfo'},
          {step: 'emailAndPhone'},
          {step: 'passport'},
          {step: 'nhsDetails'},
          {step: 'address'},
          {step: 'isolatingAddress'},
          {step: 'returnTravelDetails'},
          {step: 'vaccinationStatus'},
          {step: 'confirmDetails'}
        ]
      },
      ...antigenSubmissionPath
    ]
  }
};

/*

generates path e.g.
[
    {stage: "Personal Details", step: "names",  stageProgress: 0.0, hideBack: true},
    {stage: "Personal Details", step: "personalInfo", stageProgress: 0.33},
    {stage: "Personal Details", step: "vaccinationStatus", stageProgress: 0.66},
    {stage: "Contact Details", step: "emailAndPhone", stageProgress: 0.0},
    {stage: "Contact Details", step: "address", stageProgress: 0.50},
    {stage: "Submit", step: "reasonForTest", stageProgress: 0.0},
    {stage: "Submit", step: "swabDetails", stageProgress: 0.33},
    {stage: "Submit", step: "submission", stageProgress: 0.66}
]

*/

export const generatePath = (testType, cert) => {
  const stages = get(paths, `${testType}.${cert}`, null);

  if (!stages) {
    return null;
  }

  const path = stages.reduce((prev, current) => {
    const pathSteps = [];
    current.steps.forEach((step, stepIndex) => {
      pathSteps.push(
        {
          stage: current.stage,
          step: step.step,
          stageProgress: round(stepIndex / current.steps.length, 1),
          ...(step?.hideBack && {hideBack: true})
        }
      );
    });

    return [...prev, ...pathSteps];
  }, []);

  return path;
};
