import {Typography} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  display: block;
  margin-bottom: 10px;

  .MuiTextField-root + & {
    margin-top: 20px;
  }
`;

const CaptionText = (props) => {
  return (
    <StyledTypography variant="caption">{props.children}</StyledTypography>
  );
};

export default CaptionText;
