import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React from 'react';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
  height: 16px;
  width: 16px;
  font-size: 12px;
  background-color: ${props => props.bgcolor};
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  margin-top: 8px;
  min-width: 20px;
  align-self: start;
`;

const BulletNumberList = ({
  steps
}) => {
  const theme = useTheme();
  return (
    <>
      <List disablePadding>
        {steps.map((step, idx) => (
          <StyledListItem key={idx} disableGutters={true}>
            <StyledListItemIcon>
              <StyledAvatar bgcolor={theme.palette.primary.dark}>
                {idx + 1}
              </StyledAvatar>
            </StyledListItemIcon>
            <ListItemText
              primary={step}
            />
          </StyledListItem>
        ))}
      </List>
    </>
  );
};

export default BulletNumberList;
