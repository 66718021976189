import {
  Box,
  Typography,
  Button
} from '@mui/material';
import React from 'react';
import useHubspot from '../../services/useHubspot';
import InfoBox from '../InfoBox';
import StepLayout from '../StepLayout';

const Submission = ({
  registrationData
}) => {
  useHubspot();

  return (
    <StepLayout
      title="Wait for your certificate"
      header={
        <InfoBox
          message="Your test result has been submitted"
          level="success"
        />
      }
    >
      <Box mb={3}>
        <Box mb={2.5}>
          <Typography variant="body1" gutterBottom component="p">
            You can submit at any time of day. Results will be validated by our team <strong>between 09:00 and 17:00 UK time, 7 days a week</strong>. Your result will typically be validated <strong>within 15 minutes, but allow up to 2 hours</strong>, if submitted between these times. If submitted out of hours, your result will be validated the next day. You will be asked to upload a new photo if your photo is not legible.
          </Typography>
          <Typography variant="body1" gutterBottom component="p">
            We will send your certificate to the following email: <strong>{registrationData.email}</strong>.
          </Typography>
          <Typography variant="body1" gutterBottom component="p">
            If you have any questions, please see the FAQ or contact us.
          </Typography>
        </Box>
        <Box borderBottom={'solid 1px rgba(0, 0, 0, 0.12)'} my={3}/>
        <Box mt={4}>
          <Box mb={2}>
            <Typography component="p" variant="h3" gutterBottom>
              Do you have more tests to register?
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              href="/register/code"
              target="_blank"
            >
              Register a new kit code
            </Button>
          </Box>
        </Box>
      </Box>
    </StepLayout>
  );
};

export default Submission;
