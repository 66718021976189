const config = [
  {
    enabled: false,
    boxCode: '3c',
    kitVersion: 1,
    name: 'Manual kit (legacy)',
    hasShopifyOrderNumber: true,
    hasBarCode: false,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '4c',
    kitVersion: 2,
    name: 'Barcode hub kit',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '4d',
    kitVersion: 3,
    name: 'Barcode postal kit',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '4e',
    kitVersion: 4,
    name: 'Barcode wholesale kit',
    hasShopifyOrderNumber: false,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '5a',
    kitVersion: 5,
    name: 'Day 2 test to release kit',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '6a',
    kitVersion: 6,
    name: 'Rapid Antigen Test',
    hasShopifyOrderNumber: false,
    hasBarCode: true,
    registrationToIncludeTestDate: false,
    testType: 'RAT',
    validFor: ['basiclfd', 'preDepartureCheck'],
    canSaveManually: true,
    singleRegistrationFlow: true,
    validateId: false
  },
  {
    enabled: true,
    boxCode: '7a',
    kitVersion: 7,
    name: 'Universal PCR kit',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '7b',
    kitVersion: 8,
    name: 'Universal PCR kit (Wholesale)',
    hasShopifyOrderNumber: false,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '9a',
    kitVersion: 9,
    name: 'Rapid Antigen Test (New Box)',
    hasShopifyOrderNumber: false,
    hasBarCode: true,
    registrationToIncludeTestDate: false,
    testType: 'RAT',
    validFor: ['basiclfd', 'preDepartureCheck'],
    singleRegistrationFlow: true,
    validateId: false
  },
  {
    enabled: true,
    boxCode: '10a',
    kitVersion: 10,
    name: 'Rapid Antigen Test (Day 2 instructions, QR code)',
    hasShopifyOrderNumber: false,
    hasBarCode: true,
    registrationToIncludeTestDate: false,
    testType: 'RAT',
    validFor: ['basiclfd', 'preDepartureCheck'],
    singleRegistrationFlow: true,
    validateId: true
  },
  {
    enabled: true,
    boxCode: '11a',
    kitVersion: 11,
    name: 'Rapid Antigen Test (Interim stock)',
    hasShopifyOrderNumber: false,
    hasBarCode: true,
    registrationToIncludeTestDate: false,
    testType: 'RAT',
    validFor: ['basiclfd', 'preDepartureCheck'],
    singleRegistrationFlow: true,
    validateId: false
  },
  {
    enabled: true,
    boxCode: '12a',
    kitVersion: 12,
    name: 'Universal PCR kit (SBS interim stock)',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '13a',
    kitVersion: 13,
    name: 'Universal PCR kit (SBS)',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'PCR',
    validFor: ['basic', 'fitToFly']
  },
  {
    enabled: true,
    boxCode: '14a',
    kitVersion: 14,
    name: 'General Testing LFD Kit (Digital Kit Code)',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'RAT',
    validFor: ['basiclfd'],
    canSaveManually: true,
    singleRegistrationFlow: true,
    hasQrCode: true,
    validateId: false
  },
  {
    enabled: true,
    boxCode: '15a',
    kitVersion: 15,
    name: 'Fit to Fly LFD Kit (Digital Kit Code)',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'RAT',
    validFor: ['preDepartureCheck'],
    canSaveManually: true,
    singleRegistrationFlow: true,
    hasQrCode: true,
    validateId: false
  },
  {
    enabled: true,
    boxCode: '16a',
    kitVersion: 16,
    name: 'Day 2 LFD Kit (Digital Kit Code)',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'RAT',
    validFor: ['day2lfd'],
    canSaveManually: true,
    singleRegistrationFlow: true,
    hasQrCode: true,
    validateId: false
  },
  {
    enabled: true,
    boxCode: '17a',
    kitVersion: 17,
    name: 'Observed LFD Kit (Digital Kit Code)',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'RAT',
    validFor: ['preDepartureCheck'],
    canSaveManually: true,
    singleRegistrationFlow: true,
    hasQrCode: true,
    validateId: false
  },
  // This generates a kitcode but no physical kit - customers use their own kit
  {
    enabled: true,
    boxCode: '18a',
    kitVersion: 18,
    name: 'Video Verification Code (No Physical Kit)',
    hasShopifyOrderNumber: true,
    hasBarCode: true,
    registrationToIncludeTestDate: true,
    testType: 'RAT',
    validFor: ['preDepartureCheck'],
    canSaveManually: true,
    singleRegistrationFlow: true,
    hasQrCode: true,
    validateId: false
  }
];

export default {
  findByBoxCode: (boxCode) => config
  .filter((kitVersion) => kitVersion.enabled === true)
  .find((kitVersion) => kitVersion.boxCode === boxCode),

  findByVersion: (version) => config
  .filter((kitVersion) => kitVersion.enabled === true)
  .find((kitVersion) => kitVersion.kitVersion === version),

  filterByType: (testType) => config
  .filter((kitVersion) => kitVersion.enabled === true)
  .filter((kitVersion) => kitVersion.testType === testType),

  config
};
