import {
  Box,
  Paper,
  Typography
} from '@mui/material';
import React, {useRef, useState} from 'react';
import {
  Routes,
  Route,
  Outlet,
  Navigate
} from 'react-router-dom';
import styled from 'styled-components';
import AntigenSubmit from './AntigenSubmit';
import AppBar from './AppBar';
import Booking from './Booking';
import BookingSuccess from './BookingSuccess';
import Code from './Code';
import Collect from './Collect';
import Form from './Form';
import Status from './Status';
import Verification from './Verification';
import '@fontsource/roboto';

const StyledBox = styled(Box)`
  padding: 0;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: 600px) {
    width: 520px;
    padding: 70px 0px 70px 0px;
    flex: 0 1 auto;
    margin: auto
  }
`;

const StyledPaper = styled(Paper)`
  padding: 24px 16px;
  width: 100%;

  @media (min-width: 600px) {
    width: 100%;
    padding: 40px 40px;
  }
`;

function Layout () {
  const onBackChild = useRef(null);
  const [showStages, setShowStages] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [stages, setStages] = useState([]);
  const [currentStage, setCurrentStage] = useState({stage: '', progress: 0});

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <AppBar
        onBack={onBackChild.current}
        showBack={showBack}
        stages={stages}
        currentStage={currentStage}
        showStages={showStages}
      />
      <StyledBox>
        <StyledPaper>
          <Outlet context={[onBackChild, setShowBack, setStages, setCurrentStage, setShowStages]}/>
        </StyledPaper>
      </StyledBox>
    </Box>
  );
}

const Register = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/register/code" />} />
      <Route path="/verification/:uuid" element={<Verification/>}/>
      <Route path="/status/:uuid" element={<Status/>}/>
      <Route path="/collect/:uuid" element={<Collect/>}/>
      <Route path="/code" element={<Code/>}/>
      <Route path="/antigen/submit" element={<AntigenSubmit/>}/>
      <Route path="/booking" element={<Booking/>}/>
      <Route path="/booking/success/:bookingReference" element={<BookingSuccess/>}/>
      <Route path="/booking/success" element={<BookingSuccess/>}/>
      <Route path="/:testKitCode" element={<Form/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  );
};

const Book = () => {
  return (
    <Routes>
      <Route path="/by-order" element={<Booking/>}/>
      <Route path="/by-order/success/:bookingReference" element={<BookingSuccess/>}/>
      <Route path="/by-order/success" element={<BookingSuccess/>}/>
      <Route path="/by-code" index element={<Booking/>}/>
      <Route path="/by-code/success/:bookingReference" index element={<BookingSuccess/>}/>
      <Route path="/by-code/success" index element={<BookingSuccess/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/register/code" />} />
        <Route path="/register/*" element={<Register />} />
        <Route path="/verification/:uuid" element={<Verification />} />
        <Route path="/status/:uuid" element={<Status />} />
        <Route path="/collect/:uuid" element={<Collect />} />
        <Route path="/book/*" element={<Book />} />
        <Route path="/antigen/submit" element={<AntigenSubmit />} />
        <Route path="*" element={<NotFound/>}/>
      </Route>
    </Routes>
  );
};

function NotFound () {
  return (
    <Typography variant="h4">Page not found</Typography>
  );
}

export default App;
