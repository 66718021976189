/**
 * https://www.gov.uk/guidance/red-list-of-countries-and-territories
 */
export default [
  {
    name: 'Angola',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Botswana',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Eswatini',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Lesotho',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Malawi',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Mozambique',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Namibia',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Nigeria',
    fromDate: '2021-12-06T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'South Africa',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Zambia',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  },
  {
    name: 'Zimbabwe',
    fromDate: '2021-11-28T04:00',
    toDate: '2021-12-15T04:00'
  }
];
