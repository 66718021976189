import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';
import {
  startCase,
  pickBy
} from 'lodash';
import {DateTime} from 'luxon';
import React, {useState, useEffect} from 'react';
import {Bold} from '../utils/commonComponents';

const SubmissionConfirmation = (
  {
    formData,
    fields,
    onSubmit,
    onBackNav,
    onChange
  }
) => {
  const [confirmationProvided, setConfirmationProvided] = useState(false);
  const ignoredFields = ['confirmedSubmission', 'selectedCert', 'repeatEmail', 'covidSymptoms', 'reasonNoFly', 'boxCode'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleConfirmation = e => {
    const {checked} = e.target;

    onChange('confirmedSubmission')(checked);
    setConfirmationProvided(checked);
  };

  const filteredDataFields = pickBy(formData, (v, k) => !ignoredFields.includes(k));

  const getDataField = (key, value, displayName, type, format) => {
    return (
      <Box key={key} display="flex" width="100%">
        <Box p={0.5} flex="1" display="flex" justifyContent="flex-end" alignItems="center">
          <Typography variant="body1" component="span" align="right">
            <Box fontWeight="fontWeightBold">
              {displayName ?? startCase(key)}:
            </Box>
          </Typography>
        </Box>
        <Box p={0.5} flex="1" display="flex" alignItems="center">
          <Typography variant="body2">
            {type === 'date'
              ? DateTime.fromISO(value).isValid ? DateTime.fromISO(value).toFormat(format) : value?.toString()
              : value?.toString()
            }
          </Typography>
        </Box>
      </Box>
    );
  };

  const getFields = (fields) => {
    const respVal = fields.map(f => {
      if (f.name in filteredDataFields && f.displayInSummary) {
        return (
          getDataField(f.name, filteredDataFields[f.name], f?.displayName, f?.type, f?.format)
        );
      }
      return null;
    }).filter(Boolean);

    return respVal;
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Bold component="h1" variant="h5" gutterBottom>Confirm details</Bold>
        <Typography component="p" variant="body2" gutterBottom>Please make sure this information is correct before you submit your details</Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={2}>
        {fields.map((f, idx) => {
          return (
            <Box key={idx} mb={2}>
              {getFields(f)}
            </Box>
          );
        })}
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" my={2}>
        <FormControlLabel
          control={<Checkbox checked={confirmationProvided} onChange={toggleConfirmation} name="confirmationProvided" />}
          label="I confirm that the above details are correct."
          data-cy='confirm-details-checkbox'
        />
      </Box>

      <Box mb={1}>
        <Box mb={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            fullWidth
            disabled={!confirmationProvided}
            data-cy='submit-button'
          >
            Submit
          </Button>
        </Box>
        <Box mb={1}>
          <Button
            fullWidth
            variant="outlined"
            onClick={onBackNav}
          >
            Back
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

export default SubmissionConfirmation;
