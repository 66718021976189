export default [
  {value: 'Arab', label: 'Arab'},
  {value: 'Asian or Asian British (Bangladeshi)', label: 'Asian or Asian British (Bangladeshi)'},
  {value: 'Asian or Asian British (Chinese)', label: 'Asian or Asian British (Chinese)'},
  {value: 'Asian or Asian British (Indian)', label: 'Asian or Asian British (Indian)'},
  {value: 'Asian or Asian British (Pakistani)', label: 'Asian or Asian British (Pakistani)'},
  {value: 'Asian or Asian British (Any other)', label: 'Asian or Asian British (Any other)'},
  {value: 'Black, African, Caribbean or Black British (African)', label: 'Black, African, Caribbean or Black British (African)'},
  {value: 'Black, African, Caribbean or Black British (Caribbean)', label: 'Black, African, Caribbean or Black British (Caribbean)'},
  {value: 'Black, African, Caribbean or Black British (Any other)', label: 'Black, African, Caribbean or Black British (Any other)'},
  {value: 'Mixed or Multiple ethnic groups (White and Asian)', label: 'Mixed or Multiple ethnic groups (White and Asian)'},
  {value: 'Mixed or Multiple ethnic groups (White and Black African)', label: 'Mixed or Multiple ethnic groups (White and Black African)'},
  {value: 'Mixed or Multiple ethnic groups (White and Black Caribbean)', label: 'Mixed or Multiple ethnic groups (White and Black Caribbean)'},
  {value: 'Mixed or Multiple ethnic groups (Any other)', label: 'Mixed or Multiple ethnic groups (Any other)'},
  {value: 'White (English, Welsh, Scottish, Northern Irish or British)', label: 'White (English, Welsh, Scottish, Northern Irish or British)'},
  {value: 'White (Gypsy or Irish Traveller)', label: 'White (Gypsy or Irish Traveller)'},
  {value: 'White (Irish)', label: 'White (Irish)'},
  {value: 'White (Any other)', label: 'White (Any other)'},
  {value: 'Any other ethnic category', label: 'Any other ethnic category'},
  {value: 'Prefer not to say', label: 'Prefer not to say'}
];
