import {Alert, Box} from '@mui/material';
import {
  omit
} from 'lodash';
import React, {
  useEffect,
  useState
} from 'react';
import makeHandleChange from '../../utils/handleChange';
import makePathIndex from '../../utils/pathIndex';
import sentry from '../../utils/sentry';
import makeValidate from '../../utils/validate';
import {bookingReference as brValidator, email as emailValidator} from '../../utils/validators';
import StepLayout from '../StepLayout';
import TextField from '../TextField';
const {DateTime} = require('luxon');

const validateBookingReference = async ({setErrors, bookingReference}) => {
  const res = await fetch(
    `${process.env.REACT_API_URL}/booking/by-reference/${bookingReference}`,
    {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      }
    }
  );

  if (res.ok) {
    setErrors(
      (errors) => {
        return omit(errors, 'bookingReference');
      }
    );

    return true;
  }
  else {
    const json = await res.json();
    setErrors(current => ({
      ...current,
      matchingBooking: json.status
    }));
    return false;
  }
};

const getMatchingBooking = async ({setErrors, email, bookingReference}) => {
  try {
    const apiSearchMatchingBookingUrl = `${process.env.REACT_API_URL}/test-kit-registration/search/by-booking-and-email?email=${email}&bookingReference=${bookingReference}`;
    const res = await fetch(apiSearchMatchingBookingUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    });

    if (res.ok) {
      return res.json();
    }
    else {
      setErrors(current => ({
        ...current,
        matchingBooking: 'We can’t find a valid, unregistered booking with those details. Please check your details, or proceed to complete the form manually.'
      }));
    }

    return res.ok;
  }

  catch (e) {
    sentry.captureException(e);
  }
};

const Booking = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {bookingReference = '', email = ''} = registrationData;
  const [localData, setLocalData] = useState({email, bookingReference});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);

  const onNextStep = async () => {
    let isValidBookingReference = false;
    let foundBooking = {};

    setErrors((errors) => {
      return omit(errors, 'bookingReference', 'matchingBooking', 'email');
    });

    isValidBookingReference = await validateBookingReference({
      setErrors,
      bookingReference: localData.bookingReference
    });

    if (isValidBookingReference) {
      foundBooking = await getMatchingBooking({
        setErrors,
        email: localData.email,
        bookingReference: localData.bookingReference
      });
    }

    if (isValidBookingReference && foundBooking) {
      const data = {
        ...localData,
        ...foundBooking,
        dateOfBirth: DateTime.fromISO(foundBooking.dateOfBirth),
        arrivalDate: DateTime.fromISO(foundBooking.arrivalDate)
      };

      setRegistrationData((current) => ({
        ...current,
        ...data
      }));
      makePathIndex(path, setPathIndex)();
    }
  };

  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    const {bookingReference, email} = localData;
    if (brValidator(bookingReference).isValid && emailValidator(email).isValid) {
      setDisableNextStep(false);
      setErrors({});
    }
    else {
      setDisableNextStep(true);
    }
  }, [localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="What is your booking reference number?"
      onClickLabel='Get booking details and continue'
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <TextField
            label="Booking reference"
            name="bookingReference"
            placeholder="e.g. ODLTD1234567"
            value={localData.bookingReference}
            error={errors.bookingReference}
            onChange={handleChange('bookingReference')}
            inputProps={{maxLength: 12}}
            required
            data-cy='booking-reference-input'
          />

          <TextField
            label="Email"
            name="email"
            value={localData.email}
            error={errors.email}
            onChange={handleChange('email')}
            required
            data-cy='email-input'
          />

          {('matchingBooking' in errors) && (
            <Box display="flex" justifyContent="center" marginTop="30px">
              <Alert severity="error">{errors.matchingBooking}</Alert>
            </Box>
          )}
        </Box>
      </Box>
    </StepLayout>
  );
};

export default Booking;
