const certs = [
  {
    title: 'Fit to fly - Lateral flow',
    testType: 'lfd',
    certType: 'fitToFly',
    fullCert: 'preDepartureCheck',
    icon: 'airplaneActive',
    infoText: 'Test taken before your travel. You will get a certificate used to certify that you are fit to fly.'
  },
  {
    title: 'Day 2 - Lateral flow',
    testType: 'lfd',
    certType: 'day2',
    fullCert: 'day2lfd',
    icon: 'airplaneActive',
    infoText: 'UK public health requirement. Taken on or before Day 2 after your arrival to the UK.'
  },
  {
    title: 'Other certificates - Lateral flow',
    testType: 'lfd',
    certType: 'basic',
    fullCert: 'basiclfd',
    icon: 'airplaneInactive',
    infoText: 'A basic result certificate showing whether the COVID-19 virus was detected or not.'
  },
  {
    title: 'Fit to fly - PCR',
    testType: 'pcr',
    certType: 'fitToFly',
    fullCert: 'fitToFly',
    icon: 'airplaneActive',
    infoText: 'Test taken before your travel. You will get a certificate used to certify that you are fit to fly.'
  },
  {
    title: 'Day 2 - PCR',
    testType: 'pcr',
    certType: 'day2',
    fullCert: 'day2',
    icon: 'airplaneActive',
    infoText: 'Taken on or before Day 2 after your arrival into the UK.'
  },
  {
    title: 'Day 8 - PCR',
    testType: 'pcr',
    certType: 'day8',
    fullCert: 'day8',
    icon: 'airplaneActive',
    infoText: 'Taken on or before Day 8 after your arrival into the UK.'
  },
  {
    title: 'Test to release - PCR',
    testType: 'pcr',
    certType: 'ttr',
    fullCert: 'testToRelease',
    icon: 'airplaneActive',
    infoText: 'Allows you to decrease the time you need to quarantine if you test negative.'
  },
  {
    title: 'Other certificates - PCR',
    testType: 'pcr',
    certType: 'basic',
    fullCert: 'basic',
    icon: 'airplaneInactive',
    infoText: 'A basic result certificate showing whether the COVID-19 virus was detected or not.'
  }
];

export default certs;
