import {Alert, AlertTitle, Box, Divider, Typography} from '@mui/material';
import {DateTime} from 'luxon';
import QRCode from 'qrcode.react';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const Collect = () => {
  const [error, setError] = useState('');
  const [alert, setAlert] = useState(null);
  const [order, setOrder] = useState({});
  const [canCollect, setCanCollect] = useState(false);
  const {uuid} = useParams();

  useEffect(() => {
    (async () => {
      return fetch(`${process.env.REACT_API_URL}/orders/status/${uuid}`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(res => {
        const {status} = res;

        if (status === 200) {
          return res.json();
        }
        else {
          if (status === 404) {
            return setError('Order not found, please contact support');
          }
          else {
            return setError('There was an error. Please refresh the page.');
          }
        }
      })
      .then(data => {
        if (!data) {
          return null;
        }

        const {orderStatus, order} = data;
        const collectionDate = DateTime.fromISO(order.pickupAt).toFormat('ff');

        switch (orderStatus) {
        case 'ready':
          setCanCollect(true);
          setAlert({
            title: 'Ready',
            message: 'Order is ready for collection'
          });
          break;
        case 'fulfilled':
          setCanCollect(false);
          setAlert({
            title: 'Fulfilled',
            message: 'Order has already been collected'
          });
          break;
        case 'pending':
          setCanCollect(false);
          setAlert({
            title: 'Order is not ready for collection',
            message: `Order can be collected from ${collectionDate}`
          });
          break;
        case 'cancelled':
          setCanCollect(false);
          setAlert({
            title: 'Cancelled',
            message: `Order was cancelled on ${DateTime.fromISO(order.cancelledAt).toFormat('ff')}`
          });
          break;
        }

        return setOrder(order);
      })
      .catch(err => {
        return setError(`There was an error. Please refresh the page. ${err.stack}`);
      });
    })();
  }, []); // eslint-disable-line @medicspot/react-hooks/exhaustive-deps

  const customerDetails = (customer) => {
    const name = `${customer.first_name} ${customer.last_name}`;
    const address1 = customer.default_address.address1;
    const city = customer.default_address.city;
    const country = customer.default_address.country;
    const postcode = customer.default_address.zip;
    const fullAddress = `${address1}, ${city}, ${postcode}, ${country}`;

    return (
      <>
        <Typography
          variant="subtitle2"
          gutterBottom
        >
          Customer details
        </Typography>
        <Typography>
          <strong>Name</strong>: {name}
        </Typography>
        <Typography>
          <strong>Email</strong>: {customer.email}
        </Typography>
        <Typography>
          <strong>Address</strong>: {fullAddress}
        </Typography>
        <Divider />
      </>
    );
  };

  const orderCreatedAt = order.createdAt
    ? DateTime.fromISO(order.createdAt).toFormat('ff')
    : null;
  const orderCancelledAt = order.createdAt
    ? DateTime.fromISO(order.cancelledAt).toFormat('ff')
    : null;
  const orderPickupAt = order.pickupAt
    ? DateTime.fromISO(order.pickupAt).toFormat('ff')
    : null;

  return (
    <>
      {error && (
        <Alert variant="filled" severity="error">{error}</Alert>
      )}
      {alert && (
        canCollect
          ? (
            <Alert variant="filled" severity="success">
              <AlertTitle>{alert.title}</AlertTitle>
              {alert.message}
            </Alert>
          )
          : (
            <Alert variant="filled" severity="warning">
              <AlertTitle>{alert.title}</AlertTitle>
              {alert.message}
            </Alert>
          )
      )}
      {(order && !error) && (
        <Box mt={2}>
          <Typography variant="h5">
            <strong>Order</strong>: {order.msOrderNumber}
          </Typography>
          <Divider />
          <Box my={1}>
            <Typography
              variant="subtitle2"
              gutterBottom
            >
              Order details
            </Typography>
            {order.productName && (
              <Typography>
                <strong>Product Name</strong>: {order.productName}
              </Typography>
            )}
            <Typography>
              <strong>Quantity</strong>: {order.quantity}
            </Typography>
            <Typography>
              <strong>Order created</strong>: {orderCreatedAt}
            </Typography>
            {order.cancelledAt
              ? (
                <Typography color="error">
                  <strong>Order cancelled</strong>: {orderCancelledAt}
                </Typography>
              )
              : (
                <Typography>
                  <strong>Can collect from</strong>: {orderPickupAt}
                </Typography>
              )}
          </Box>
          <Divider />
          {order.payloadData?.customer && (
            <Box my={1}>
              {customerDetails(order.payloadData.customer)}
            </Box>
          )}
          <Box mt={1} textAlign="right">
            <QRCode value={`https://testkit.outcomedx.co.uk/collect/${order.uuid}`} size={80}/>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Collect;
