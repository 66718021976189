import {
  AirplanemodeInactive,
  AirplanemodeActive,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React from 'react';
import styled from 'styled-components';
import certs from '../data/certs';
import {generatePath} from '../utils/paths';
import LateralFlow from './instructions/LateralFlow';
import PCR from './instructions/PCR';

const StyledCard = styled(Card)`
  border-left: 4px solid ${props => props.color};
  background-color: #fafafa;
`;

const StyledCardContent = styled(CardContent)`
  padding: 0;
`;

const SelectCert = ({
  path,
  setPath,
  setPathIndex,
  setRegistrationData,
  currentCert,
  setCert,
  validFor
}) => {
  const theme = useTheme();
  const selectedCerts = validFor?.length ? certs.filter((cert) => validFor.includes(cert.fullCert)) : [];

  const handleClick = (cert) => {
    if (currentCert !== cert) {
      setRegistrationData(({selectedCert: cert.fullCert}));
      setCert(cert);
    }
    setPath(generatePath(cert.testType, cert.certType));
  };

  const handleStart = () => {
    setPathIndex(0);
  };

  if (!path.length) {
    return (
      <>
        <Box mb={1}>
          <Typography variant="h2" gutterBottom component="h2">
            Select your certificate
          </Typography>
          <Typography variant="body1" gutterBottom component="p">
            Select the type of certificate you require.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {selectedCerts.map(cert => (
            <Grid item xs={12} key={cert.fullCert}>
              <StyledCard color={theme.palette.primary.main}>
                <CardActionArea data-cy={`${cert.fullCert}-card`} onClick={() => handleClick(cert)} >
                  <StyledCardContent>
                    <Box display="flex" justifyContent="space-between" py={1} pl={2.5} pr={1}>
                      <Box minHeight="111px" display="flex" justifyContent="center" flexDirection="column">
                        <Box display="flex" mb={1}>
                          <Box display="flex" justifyContent="center" flexDirection="column" mr={0.5} color={theme.palette.primary.dark}>
                            {cert.icon === 'airplaneActive'
                              ? <AirplanemodeActive style={{fontSize: 16}} />
                              : <AirplanemodeInactive style={{fontSize: 16}} />
                            }
                          </Box>
                          <Box display="flex" justifyContent="center" flexDirection="column">
                            <Typography variant="h5" component="h5">
                              {cert.title}
                            </Typography>
                          </Box>
                        </Box>
                        <Box mr={4}>
                          <Typography gutterBottom variant="body2" component="p">
                            {cert.infoText}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" justifyContent="center" color={theme.palette.primary.main}>
                        <ArrowForwardIosIcon />
                      </Box>
                    </Box>
                  </StyledCardContent>
                </CardActionArea>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
  else {
    return (
      <>
        {currentCert.testType === 'lfd'
          ? <LateralFlow onClick={handleStart} certType={currentCert.fullCert} />
          : <PCR onClick={handleStart} certType={currentCert.fullCert} />
        }
      </>
    );
  }
};

export default SelectCert;
