import {
  Typography
} from '@mui/material';
import styled from 'styled-components';

const Bold = styled(Typography)`
  font-weight: bold;
`;

const Subtitle = styled(Typography)`
  margin-bottom: 15px
`;

const List = styled.ul`
  margin: 0
`;

export {
  Bold,
  Subtitle,
  List
};
