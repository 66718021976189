import {
  Box,
  MenuItem,
  Typography
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import TextField from './TextField';

// This to allow long labels be displayed over multiple lines
const StyledMenuItem = styled(MenuItem)`
  white-space: normal
`;

const StyledTextField = styled(TextField)`
.MuiSelect-select {
    white-space: normal;
  }
`;

const Select = ({
  label,
  options,
  onChange,
  value,
  error,
  required,
  title,
  name,
  'data-cy': dataCy
}) => {
  return (
    <Box mb={2}>
      {title && <Typography variant="h4" component="h4" gutterBottom mb={2}>{title}</Typography>}
      <StyledTextField
        select
        label={label}
        name={name}
        value={value}
        error={error}
        onChange={onChange}
        required={required}
        data-cy={dataCy}
      >
        <StyledMenuItem aria-label="None" value="">Select</StyledMenuItem>
        {options.map(o => (
          <StyledMenuItem key={o.value} value={o.value}>{o.label}</StyledMenuItem>
        ))}
      </StyledTextField>
    </Box>
  );
};

export default Select;
