import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  CardContent,
  Box,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {genderOptions} from '../../data/genderOptions';
import {transportOptions} from '../../data/transportOptions';
import {vaccinatedOptions} from '../../data/vaccinatedOptions';
import ga from '../../services/google_analytics';
import {getRegistration} from '../../utils/getRegistration';
import getTransportFieldLabel from '../../utils/getTransportFieldLabel';
import makePathIndex from '../../utils/pathIndex';
import sentry from '../../utils/sentry';
import StepLayout from '../StepLayout';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    background: 'rgba(48,127,226,0.08)'
  }
});

const Card = ({children, onClick}) => {
  const classes = useStyles();
  return (
    <Box variant="outlined" className={classes.card} mb={0.5} onClick={onClick}>
      <CardContent className={classes.cardContent}>
        <Box display={'flex'} justifyContent={'space-between'} >
          <Box>
            {children}
          </Box>
          <EditIcon style={{fontSize: 20, color: '#307FE2'}}/>
        </Box>
      </CardContent>
    </Box>
  );
};

const submitRegistration = async ({data, kitCode, kitVersion}) => {
  try {
    const res = await fetch(`${process.env.REACT_API_URL}/test-kit-registrations/form-submit`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...data,
        email: data.email.toLowerCase(),
        repeatEmail: data.repeatEmail.toLowerCase(),
        kitCode,
        certType: data.selectedCert,
        enhanced: data.selectedCert === 'fitToFly' || data.selectedCert === 'preDepartureCheck'
      })
    });

    if (res.status === 200) {
      ga.event({
        category: 'F2FRegistrationForm',
        action: 'REGISTRATION_FORM_SUCCESS',
        nonInteraction: true
      });

      return {
        success: true,
        message: ''
      };
    }

    if (res.status === 401) {
      const {message} = await res.json();
      if (message === 'Test Kit already registered' || message === 'Order details not found') {
        ga.event({
          category: 'F2FRegistrationForm',
          action: 'REGISTRATION_FORM_ERROR',
          nonInteraction: true
        });
      }
      return {
        success: false,
        message
      };
    }
    return {
      success: false,
      message: 'Submission failed.'
    };
  }
  catch (e) {
    sentry.captureException(e);
    return {
      success: false,
      message: 'Submission failed.'
    };
  }
};

const ConfirmDetails = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData,
  setSubmissionData,
  kitVersion
}) => {
  const {testKitCode} = useParams();
  const [submissionError, setSubmissionError] = useState('');
  const [confirmationProvided, setConfirmationProvided] = useState(false);

  const handleClick = (field) => () => {
    const index = path.findIndex(element => element.step === field);
    if (index >= 0) {
      setPathIndex(index);
    }
  };

  const onNextStep = async () => {
    const submissionResult = await submitRegistration({data: registrationData, kitCode: testKitCode, kitVersion});

    if (submissionResult.success) {
      const res = await getRegistration(registrationData.email.toLowerCase(), testKitCode);
      if (res.success) {
        setRegistrationData((current) => ({
          ...current,
          bookingReference: res.data.bookingReference,
          kitVersion: res.data.kitVersion,
          kitCode: res.data.kitCode
        }));

        setSubmissionData((current) => ({
          ...current,
          bookingReference: res.data.bookingReference,
          kitVersion: res.data.kitVersion,
          kitCode: res.data.kitCode
        }));
      }
      // if user refreshes page they have to re-enter email to validate
      // simply increment to next page without any parameters
      makePathIndex(path, setPathIndex)();
    }
    else {
      setSubmissionError(submissionResult.message);
    }
  };

  return (
    <StepLayout onClick={onNextStep}
      onClickLabel="Register"
      title="Confirm details" subtitle="Please make sure this information is correct before you submit your details."
      onClickDisabled={!confirmationProvided}
    >
      <Box mb={3}>
        <Box mb={2.5}>
          <Typography variant="body1" component="p">
            Please make sure this information is correct before you submit your details.
          </Typography>
        </Box>
        <Box mb={2}>
          {registrationData?.bookingReference &&
            <Card onClick={handleClick('booking')}>
              <Typography variant="subtitle2">Booking</Typography>
              <Typography variant="body2">
                {registrationData.bookingReference}
              </Typography>
            </Card>
          }

          {registrationData?.firstName && registrationData?.lastName &&
            <Card onClick={handleClick('names')}>
              <Typography variant="subtitle2">Name</Typography>
              <Typography variant="body2">
                {registrationData.firstName}{registrationData.middleName.length ? ` ${registrationData.middleName}` : ''} {registrationData.lastName}
              </Typography>
            </Card>
          }

          {registrationData?.dateOfBirth && registrationData?.gender && registrationData?.ethnicity &&
            <Card onClick={handleClick('personalInfo')}>
              <Typography variant="subtitle2">Date of birth</Typography>
              <Typography variant="body2">
                {DateTime.fromISO(registrationData.dateOfBirth).toFormat('dd/MM/yyyy')}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Gender</Typography>
              <Typography variant="body2">
                {genderOptions.find(option => option.value === registrationData.gender)?.label ?? 'Unknown'}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Ethnicity</Typography>
              <Typography variant="body2">
                {registrationData.ethnicity}
              </Typography>
            </Card>
          }

          {registrationData?.email && registrationData?.phoneNumber &&
            <Card onClick={handleClick('emailAndPhone')}>
              <Typography variant="subtitle2">Email</Typography>
              <Typography variant="body2">
                {registrationData.email}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Phone number</Typography>
              <Typography variant="body2">
                {registrationData.phoneNumber}
              </Typography>
            </Card>
          }

          {registrationData?.passportIssuer && registrationData?.passportNumber &&
            <Card onClick={handleClick('passport')}>
              {registrationData?.passengerLocatorReference && <>
                <Typography variant="subtitle2">Passenger locator reference</Typography>
                <Typography variant="body2">
                  {registrationData.passengerLocatorReference}
                </Typography>
                <br/>
              </>}
              <Typography variant="subtitle2">Passport issuer</Typography>
              <Typography variant="body2">
                {registrationData.passportIssuer}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Passport number</Typography>
              <Typography variant="body2">
                {registrationData.passportNumber}
              </Typography>
            </Card>
          }

          {registrationData?.address1 && registrationData?.city && registrationData?.postcode && registrationData?.country &&
            <Card onClick={handleClick('address')}>
              <Typography variant="subtitle2">Address</Typography>
              <Typography variant="body2">
                {registrationData.address1}{registrationData.address2 ? <><br/>{registrationData.address2}<br/></> : <br/>}{registrationData.city}<br/>{registrationData.postcode}<br/>{registrationData.country}
              </Typography>
            </Card>
          }

          {registrationData?.departureDate && registrationData?.destinationCountry &&
            <Card onClick={handleClick('outboundTravelDetails')}>
              <Typography variant="subtitle2">Departure date</Typography>
              <Typography variant="body2">
                {DateTime.fromISO(registrationData.departureDate).toFormat('dd/MM/yyyy HH:mm')}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Destination country</Typography>
              <Typography variant="body2">
                {registrationData.destinationCountry}
              </Typography>
            </Card>
          }

          {
            registrationData?.nonExemptCountryDate &&
          registrationData?.arrivalDate &&
          registrationData?.departureCountry &&
          registrationData?.departureCity &&
          registrationData?.transportType &&
          registrationData?.flightCoachNumber &&
            <Card onClick={handleClick('returnTravelDetails')}>
              <Typography variant="subtitle2">Departure date (UK time)</Typography>
              <Typography variant="body2">
                {DateTime.fromISO(registrationData.nonExemptCountryDate).toFormat('dd/MM/yyyy HH:mm')}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Date of arrival into UK</Typography>
              <Typography variant="body2">
                {DateTime.fromISO(registrationData.arrivalDate).toFormat('dd/MM/yyyy HH:mm')}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Travelling country</Typography>
              <Typography variant="body2">
                {registrationData.departureCountry}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Travelling city</Typography>
              <Typography variant="body2">
                {registrationData.departureCity}
              </Typography>
              <br/>
              <Typography variant="subtitle2">Type of transport</Typography>
              <Typography variant="body2">
                {transportOptions.find(option => option.value === registrationData.transportType)?.label ?? 'Unknown'}
              </Typography>
              <br/>
              <Typography variant="subtitle2">{getTransportFieldLabel(registrationData.transportType)}</Typography>
              <Typography variant="body2">
                {registrationData.flightCoachNumber}
              </Typography>
            </Card>
          }

          {registrationData?.vaccinated &&
            <Card onClick={handleClick('vaccinationStatus')}>
              <Typography variant="subtitle2">Vaccinated</Typography>
              <Typography variant="body2">
                {vaccinatedOptions.find(option => option.value === registrationData.vaccinated)?.label ?? 'Unknown'}
              </Typography>
            </Card>
          }

          {(registrationData?.rmTrackingNumber || registrationData?.dxTrackingNumber || registrationData?.confirmNoTrackingNumber) &&
            <Card onClick={handleClick('deliveryTrackingNumber')}>
              <Typography variant="subtitle2">Delivery Tracking Number</Typography>
              <Typography variant="body2">
                {registrationData?.rmTrackingNumber
                  ? `${registrationData?.rmTrackingNumber}`
                  : registrationData?.dxTrackingNumber
                    ? `${registrationData?.dxTrackingNumber}`
                    : 'No tracking number provided'
                }
              </Typography>
            </Card>
          }

          {registrationData?.timeOfSwab &&
            <Card onClick={handleClick('swabDetails')}>
              <Typography variant="subtitle2">Date and time of swab</Typography>
              <Typography variant="body2">
                {DateTime.fromISO(registrationData.timeOfSwab).toFormat('dd/MM/yyyy HH:mm')}
              </Typography>
            </Card>
          }

          {registrationData?.isolatingAddress1 && registrationData?.isolatingCity && registrationData?.isolatingPostcode && registrationData?.isolatingCountry &&
            <Card onClick={handleClick('isolatingAddress')}>
              <Typography variant="subtitle2">Isolating address</Typography>
              <Typography variant="body2">
                {registrationData.isolatingAddress1}{registrationData.isolatingAddress2 ? <><br/>{registrationData.isolatingAddress2}<br/></> : <br/>}{registrationData.isolatingCity}<br/>{registrationData.isolatingPostcode}<br/>{registrationData.isolatingCountry}
              </Typography>
            </Card>
          }

          {registrationData?.whyNeedTest &&
            <Card onClick={handleClick('reasonForTest')}>
              <Typography variant="subtitle2">Reason for test</Typography>
              <Typography variant="body2">
                {registrationData.whyNeedTest}
              </Typography>
            </Card>
          }

          {registrationData?.nhsNumber &&
            <Card onClick={handleClick('nhsDetails')}>
              <Typography variant="subtitle2">NHS number</Typography>
              <Typography variant="body2">
                {registrationData.nhsNumber}
              </Typography>
            </Card>
          }
        </Box>
        <Box>
          <FormControlLabel
            control={<Checkbox data-cy='confirm-details-checkbox' checked={confirmationProvided} onChange={(event) => {
              const {checked} = event.target;
              setConfirmationProvided(checked);
            }} name="confirmationProvided" />}
            label="I confirm that the above details are correct."
          />
        </Box>
      </Box>
      {!!submissionError.length && (
        <Box display="flex" justifyContent="center" my={3}>
          <Alert severity="error">{submissionError}</Alert>
        </Box>
      )}
    </StepLayout>
  );
};

export default ConfirmDetails;
