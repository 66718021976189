import {
  ArrowBackIos as ArrowBackIosIcon
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Toolbar
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import {
  getFaq,
  getLogo
} from '../utils/brandSpecific';
import LinearProgressBar from './LinearProgressBar';
import Stepper from './Stepper';

const Image = styled.img`
  max-width: 140px;
  max-height: 25px;
  height: auto;
`;

// Button was hard to restyle - min+max width worked
// https://stackoverflow.com/questions/51039448/how-to-resize-a-material-ui-button
const StyledButton = styled(Button)`
  min-width: 46px;
  max-width: 46px;
  min-height: 30px;
  max-height: 30px;
  font-size: 13px;
`;

const StyledAppBar = ({
  onBack,
  showBack,
  stages,
  currentStage,
  showStages
}) => {
  const logo = getLogo();
  const faq = getFaq();
  return (
    <AppBar position="static" style={{background: '#ffffff'}} >
      <Toolbar style={{display: 'flex', justifyContent: 'space-between', padding: '0 8px'}}>
        <Box flexBasis="120px" display="flex">
          {showBack && <Button style={{color: '#2D2926'}} onClick={onBack}>
            <ArrowBackIosIcon/> back
          </Button>}
        </Box>
        <Box flexBasis="120px" px={2} py={1}>
          {logo && <Image src={logo} alt="Medicspot logo" />}
        </Box>
        <Box flexBasis="120px" display="flex" justifyContent="flex-end">
          {faq && <StyledButton variant="outlined" href={faq} target="_blank" color="primary" >
            FAQ
          </StyledButton>}
        </Box>
      </Toolbar>
      {showStages && <Stepper stages={stages} currentIndex={stages.findIndex(stage => stage === currentStage.stage)}/>}
      <LinearProgressBar value={currentStage?.progress}/>
    </AppBar>
  );
};

export default StyledAppBar;
