import {Typography, Alert} from '@mui/material';
import {capitalize} from 'lodash';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Timeline from './Timeline';

const getResultDeadline = (receivedAt) => {
  const dt = new DateTime(receivedAt);

  if (dt.isValid) {
    return dt.plus({hours: 48}).toLocaleString({
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  else {
    return 'N/A';
  }
};

const getStatusInfoText = ({status, receivedAt, brand}) => {
  const supportUrl = brand === 'medicspot' ? 'https://medicspot.co.uk/contact' : 'https://outcomedx.co.uk/contact';

  if (status === 'registered') {
    return `Your test kit has been registered and we’re waiting for your test to arrive at the lab.

If you drop your test off at a Covid Test Hub before 2pm, it will normally arrive at the lab by midnight the same day.

If you drop your test off at a Covid Test Hub after 2pm, it will normally arrive at the lab by midnight the next day that the Covid Test Hub is open.

If you send your test by post, please track your test kit with the tracking details provided for an accurate idea of when it should arrive at the lab.

You will receive an email as soon your test is received by the lab and another email with your test results as soon as we have received sufficient information from the lab.

We aim to email your test results within 48 hours of your test kit arriving at the lab.
`;
  }

  if (status === 'received') {
    return `Your test kit has arrived safely at the lab and the lab is processing your test.

We aim to email you your test results by ${getResultDeadline(receivedAt)}

We will email your test results to you as soon as we have received sufficient information from the lab.
`;
  }

  if (status === 'completed') {
    return (
      <>
        <p>Your COVID-19 test results have been emailed to you along with your certificate.</p>

        <p>Please check your spam folder if you haven’t received your results. If you still can’t locate your results email, please <a href={supportUrl}>contact us</a>.</p>
      </>
    );
  }

  return '';
};

const Status = () => {
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');
  const {uuid} = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${process.env.REACT_API_URL}/test-kits/status/${uuid}`, {
          headers: {
            Accept: 'application/json'
          }
        });

        if (res.status === 404) {
          setError('The url is not valid, please contact support');
        }
        else {
          const json = await res.json();

          setStatus(json);
        }
      }
      catch (e) {
        setError(`There was an error. Please refresh the page. ${e.stack}`);
      }
    })();
  }, []); // eslint-disable-line @medicspot/react-hooks/exhaustive-deps

  const statusText = getStatusInfoText(status);

  return (
    <>
      {error && (
        <Alert severity="error">{error}</Alert>
      )}
      {status && (
        <div>
          <Typography variant="body1">
            <strong>KitCode</strong>: {status.kitCode}
          </Typography>
          <Typography variant="body1" status={status.status}>
            <strong>Status</strong>: {capitalize(status.status)}
          </Typography>
          <Timeline status={status} />
          <Typography variant="body1" component="div">
            {typeof statusText === 'string' ? statusText.split('\n').filter(Boolean).map(t => <p key={t}>{t}</p>) : statusText}
          </Typography>
        </div>
      )}
    </>
  );
};

export default Status;
