import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import makeHandleChange from '../../utils/handleChange';
import makeValidate from '../../utils/validate';
import * as v from '../../utils/validators';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const DeliveryTrackingNumber = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {rmTrackingNumber = '', dxTrackingNumber = '', confirmNoTrackingNumber = false} = registrationData;
  const [localData, setLocalData] = useState({rmTrackingNumber, dxTrackingNumber, confirmNoTrackingNumber});
  const [errors, setErrors] = useState({});
  const [disableNextStep, setDisableNextStep] = useState(true);

  const validate = (field, value) => {
    if (v?.[field]) {
      const {isValid, message} = v[field](value, localData);
      if (setErrors) {
        if (!isValid) {
          setErrors({
            [field]: message
          });
        }
        else {
          setErrors({});
        }
      }
      return isValid;
    }
    return true;
  };
  const handleChange = makeHandleChange(validate, setLocalData);

  const onNextStep = () => {
    const validForm = [validate('rmTrackingNumber', localData.rmTrackingNumber), validate('dxTrackingNumber', localData.dxTrackingNumber), localData.confirmNoTrackingNumber].filter(i => i).length === 1;

    if (validForm) {
      setRegistrationData((current) => ({
        ...current,
        ...localData
      }));
      setPathIndex((currentIndex) => {
        return currentIndex + 1 < path.length ? currentIndex + 1 : currentIndex;
      });
    }
  };

  useEffect(() => {
    const validate = makeValidate(null, localData);

    // Only 1 item is valid
    const validForm = [validate('rmTrackingNumber', localData.rmTrackingNumber), validate('dxTrackingNumber', localData.dxTrackingNumber), localData.confirmNoTrackingNumber].filter(i => i).length === 1;

    setDisableNextStep(!validForm);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="What is your parcel tracking number?"
      onClickDisabled={disableNextStep}
    >
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="body1" component="p">
            Please provide either a Royal Mail Tracking Number <strong>or</strong> DX Tracking number depending on how you are returning your sample. You only need to provide one type of tracking number.
          </Typography>
        </Box>
        <TextField
          label="Royal Mail Tracking Number"
          name="rmTrackingNumber"
          value={localData.rmTrackingNumber}
          error={errors.rmTrackingNumber}
          onChange={handleChange('rmTrackingNumber')}
          inputProps={{maxLength: 50}}
          data-cy='rm-tracking-number-input'
          helperText="e.g. RM012345678GB"
          disabled={localData.dxTrackingNumber.length || localData.confirmNoTrackingNumber}
        />
        <TextField
          label="DX Tracking Number"
          name="dxTrackingNumber"
          value={localData.dxTrackingNumber}
          error={errors.dxTrackingNumber}
          onChange={handleChange('dxTrackingNumber')}
          inputProps={{maxLength: 50}}
          data-cy='dx-tracking-number-input'
          helperText="e.g. 0123456789"
          disabled={localData.rmTrackingNumber.length || localData.confirmNoTrackingNumber}
        />
      </Box>
      <Box>
        <Box mb={1}>
          <Typography variant="body1" component="p">
            If you cannot provide your tracking number, we will be unable to support with tracking your sample until it arrives at the lab, and will be unable to support with any courier queries.
          </Typography>
        </Box>
        <FormControlLabel
          control={<Checkbox data-cy='confirm-no-tracking-number-checkbox' checked={localData.confirmNoTrackingNumber} onChange={(event) => {
            const {checked} = event.target;
            setErrors({});
            setLocalData(current => ({...current, ...{confirmNoTrackingNumber: checked}}));
          }} name="confirmNoTrackingNumber" />}
          label="I cannot provide a tracking number and understand my kit cannot be tracked and courier queries or delays cannot be supported"
          disabled={localData.rmTrackingNumber.length || localData.dxTrackingNumber.length}
        />
      </Box>
    </StepLayout>
  );
};

export default DeliveryTrackingNumber;
