import {
  Box,
  Typography
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {resultOptions} from '../../data/resultOptions';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import RadioButtons from '../RadioButtons';
import StepLayout from '../StepLayout';

const Image = styled.img`
  max-width: 100%;
  max-height: 600px;
`;

const SelfReviewResult = ({
  path,
  setPathIndex,
  submissionData,
  setSubmissionData
}) => {
  const {selfReviewResult = ''} = submissionData;
  const [localData, setLocalData] = useState({selfReviewResult});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setSubmissionData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="What does your result look like for you?*"
      onClickDisabled={disableNextStep}
      header={
        <Box mb={2.5} display="flex" justifyContent="center">
          <Image src="https://res.cloudinary.com/medicspot/image/upload/v1645111064/your-result-background2x.png" alt="Layout" />
        </Box>
      }
    >
      <Box mb={3}>
        <Box mb={3}>
          <Typography component="p" variant="body1" gutterBottom>
            Our team will validate your response before sending the certificate.
          </Typography>
        </Box>
        <Box>
          <RadioButtons
            options={resultOptions}
            onChange={handleChange('selfReviewResult')}
            value={localData.selfReviewResult}
            error={errors.selfReviewResult}
            required
            data-cy="self-review-result-radio"
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default SelfReviewResult;
