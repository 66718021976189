import {
  Box,
  MenuItem
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {transportOptions} from '../../data/transportOptions';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import getTransportFieldLabel from '../../utils/getTransportFieldLabel';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import CaptionText from '../CaptionText';
import CountrySelect from '../CountrySelect';
import DateTime from '../DateTime';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const ReturnTravelDetails = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {nonExemptCountryDate = '', arrivalDate = '', departureCountry = '', departureCity = '', transportType = '', flightCoachNumber = ''} = registrationData;
  const [localData, setLocalData] = useState({nonExemptCountryDate, arrivalDate, departureCountry, departureCity, transportType, flightCoachNumber});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  useEffect(() => {
    const validate = makeValidate(setErrors, {nonExemptCountryDate: localData.nonExemptCountryDate, arrivalDate: localData.arrivalDate});
    validate('nonExemptCountryDate', localData.nonExemptCountryDate);
    validate('arrivalDate', localData.arrivalDate);
  }, [localData.nonExemptCountryDate, localData.arrivalDate]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Return Travel Details"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <DateTime
            label="Departure date"
            placeholder="Departure date (UK time)"
            value={localData.nonExemptCountryDate}
            error={errors.nonExemptCountryDate}
            onChange={handleChange('nonExemptCountryDate')}
            required
            data-cy="departure-date-picker"
          />

          <DateTime
            label="Date of arrival into UK"
            value={localData.arrivalDate}
            error={errors.arrivalDate}
            onChange={handleChange('arrivalDate')}
            required
            data-cy="arrival-date-picker"
          />

          <CaptionText>The country or territory you were travelling from when you arrived in the UK.</CaptionText>
          <CountrySelect
            label="Travelling country"
            name="travellingCountry"
            value={localData.departureCountry}
            error={errors.departureCountry}
            onChange={handleChange('departureCountry')}
            required
            data-cy="departure-country-select"
          />

          <TextField
            label="Travelling city"
            value={localData.departureCity}
            error={errors.departureCity}
            onChange={handleChange('departureCity')}
            required
            data-cy="departure-city-input"
          />

          <TextField
            select
            label="Type of transport"
            value={localData.transportType}
            error={errors.transportType}
            onChange={handleChange('transportType')}
            required
            data-cy="transport-type-select"
          >
            <MenuItem aria-label="None" value="">Select</MenuItem>
            {transportOptions.map(c => (
              <MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>
            ))}
          </TextField>

          <CaptionText>Traveller&apos;s coach number, flight number or vessel name (as appropriate)</CaptionText>
          <TextField
            label={getTransportFieldLabel(localData.transportType)}
            value={localData.flightCoachNumber}
            error={errors.flightCoachNumber}
            onChange={handleChange('flightCoachNumber')}
            required
            data-cy="flight-coach-number-input"
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default ReturnTravelDetails;
