import {
  omit
} from 'lodash';
import * as v from './validators';

const validate = (setErrors = null, localData) => (field, value) => {
  if (v?.[field]) {
    const {isValid, message} = v[field](value, localData);
    if (setErrors) {
      if (!isValid) {
        setErrors(current => ({
          ...current,
          [field]: message
        }));
      }
      else {
        setErrors((errors) => (omit(errors, field)));
      }
    }
    return isValid;
  }
  return true;
};

export default validate;
