import {Box} from '@mui/material';
import React, {
  useState, useEffect
} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import CountrySelect from '../CountrySelect';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const Address = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {address1 = '', address2 = '', city = '', postcode = '', country = ''} = registrationData;
  const [localData, setLocalData] = useState({address1, address2, city, postcode, country});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="What is your home address?"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <TextField
            label="Address 1"
            name="address1"
            value={localData.address1}
            error={errors.address1}
            onChange={handleChange('address1')}
            required
            data-cy="address1-input"
          />
          <TextField
            label="Address 2"
            name="address2"
            value={localData.address2}
            error={errors.address2}
            onChange={handleChange('address2')}
            data-cy="address2-input"
          />
          <TextField
            label="City/Town"
            name="city"
            value={localData.city}
            error={errors.city}
            onChange={handleChange('city')}
            inputProps={{maxLength: 50}}
            required
            data-cy="city-input"
          />
          <TextField
            label="Postcode"
            name="postcode"
            value={localData.postcode}
            error={errors.postcode}
            onChange={handleChange('postcode')}
            inputProps={{maxLength: 15}}
            required
            data-cy="postcode-input"
          />
          <CountrySelect
            label="Country"
            name="country"
            value={localData.country}
            error={errors.country}
            onChange={handleChange('country')}
            required
            data-cy="country-select"
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default Address;
