import {
  Box
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import StepLayout from '../StepLayout';
import YesNo from '../YesNo';

// We should remove this page completely
// It is silly we are asking questions like this and blocking progress based on the answer
const Symptoms = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {covidSymptoms = null, reasonNoFly = null} = registrationData;
  const [localData, setLocalData] = useState({covidSymptoms, reasonNoFly});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);

  // symptoms answers are being appended to global registrationData, is this necessary?
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Symptoms"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <YesNo
            label="In the last 2 weeks, have you had any symptoms of COVID-19? e.g. fever, cough, loss of taste or smell"
            onChange={handleChange('covidSymptoms')}
            defaultVal={localData.covidSymptoms}
            error={errors.covidSymptoms}
            dataCy='covid-symptoms-yes-no'
          />
          <YesNo
            label="Do you have any other medical reason why you should not fly? e.g. recent heart attack or stroke, unstable angina, severe COPD, DVT, recent surgery, or more than 32 weeks pregnant"
            onChange={handleChange('reasonNoFly')}
            defaultVal={localData.reasonNoFly}
            error={errors.reasonNoFly}
            dataCy='reason-no-fly-yes-no'
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default Symptoms;
