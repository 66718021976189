import {
  Box,
  Typography
} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import dateDifferenceString from '../../utils/dateDifferenceString';
import makePathIndex from '../../utils/pathIndex';
import BulletNumberList from '../BulletNumberList';
import InfoBox from '../InfoBox';
import StepLayout from '../StepLayout';

const NextSteps = ({
  path,
  setPathIndex,
  registrationData
}) => {
  const navigate = useNavigate();
  const onNextStep = makePathIndex(path, setPathIndex);

  // Note: we need to make sure the copy of this reflects what someone who is doing a Basic LFD would expect to see
  return (
    <StepLayout
      onClick={onNextStep}
      onClickLabel="Take my test now"
      secondaryOnClick={() => navigate('/antigen/submit')}
      secondaryOnClickLabel="Come back later"
      title="Next steps"
      header={
        <Box mb={2.5}>
          <InfoBox
            message="Your test kit is now registered"
            level="success"
          />
        </Box>
      }
    >
      <Box mb={3}>
        <Box mb={2.5}>
          <Typography variant="body1" gutterBottom component="p">
            Make sure you are taking the test at the correct time for your travel. Check rules on offical websites.
          </Typography>
        </Box>
        {registrationData.departureDate && (
          <Box mb={2.5}>
            <Typography variant="h5" component="p">
              Your departure is in {dateDifferenceString(DateTime.fromISO(registrationData.departureDate), DateTime.local())}
            </Typography>
          </Box>
        )}
        <BulletNumberList steps={['Check the right time to do the test', 'Take your test and wait for the result', 'Upload your result']} />
      </Box>
    </StepLayout>
  );
};

export default NextSteps;
