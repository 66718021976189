import * as sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

const createSentry = ({
  environment,
  enabled,
  appVersion,
  codeVersion,
  dsn,
  projectName
}) => {
  if (!environment || !dsn || !projectName) {
    console.error(`environment: ${environment}, dsn: ${dsn}, projectName: ${projectName}`);
    throw Error('environment, dsn and projectName are required');
  }

  sentry.init({
    dsn: dsn,
    enabled: enabled,
    environment,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          /\*.medicspot.co.uk/,
          /^\//
        ]
      })
    ],

    // Only capture small sample of events in production
    tracesSampleRate: environment === 'production' ? 0.2 : 1.0,
    release: `${projectName}-${codeVersion}`
  });

  const captureException = sentry.captureException;

  return {
    captureException
  };
};

export default createSentry({
  appVersion: process.env.REACT_APP_VERSION,
  codeVersion: process.env.CODE_VERSION,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.REACT_APP_SENTRY_ENABLED,
  environment: process.env.REACT_APP_SENTRY_ENV,
  projectName: 'web-testkit'
});
