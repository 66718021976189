import {TextField} from '@mui/material';
import {omit} from 'lodash';
import React from 'react';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
  & + &,
  .MuiFormControl-root + & {
    margin-top: 20px;
  }
`;

const MSTextField = (props) => {
  return (
    <StyledTextField
      fullWidth
      variant="outlined"
      error={!!props.error}
      helperText={props.error ?? props.helperText}
      inputProps={{
        maxLength: 100,
        ...props.inputProps
      }}
      {...omit(props, ['error', 'inputProps', 'helperText'])}
    />
  );
};

export default MSTextField;
