export const transportFieldLabels = {
  air: 'Flight number',
  road: 'Coach number',
  sea: 'Vessel number',
  train: 'Train number',
  default: 'Coach/Flight/Vessel number'
};

const getTransportFieldLabel = (transportType) => {
  return transportFieldLabels[transportType] ?? transportFieldLabels.default;
};

export default getTransportFieldLabel;
