// Would be nice to load these when we load the app in index, just putting here for now

const brands = {
  medicspot: {
    logo: 'https://res.cloudinary.com/medicspot/image/upload/v1579604870/Medicspot.png',
    faq: 'https://www.medicspot.co.uk/covid-test' // We should add an anchor tag to the faq section on this page
  },
  outcomeDx: {
    logo: 'https://res.cloudinary.com/medicspot/image/upload/v1644233531/Outcomedx.png',
    faq: 'https://outcomedx.co.uk/products/outcome-dx'
  }
};

const brandHostMapping = {
  'testkit.medicspot.localhost': brands.medicspot,
  'testkit.dev.medicspot.co.uk': brands.medicspot,
  'testkit.medicspot.co.uk': brands.medicspot,
  'tests.outcomedx.co.uk': brands.outcomeDx
};

export const getLogo = () => {
  return brandHostMapping[window.location.host]?.logo;
};

export const getFaq = () => {
  return brandHostMapping[window.location.host]?.faq;
};
