import {
  Box
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {vaccinatedOptions} from '../../data/vaccinatedOptions';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import RadioButtons from '../RadioButtons';
import StepLayout from '../StepLayout';

const VaccinationStatus = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {vaccinated = ''} = registrationData;
  const [localData, setLocalData] = useState({vaccinated});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Your COVID-19 status"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <RadioButtons
            label="What’s your vaccination status against COVID-19?*"
            options={vaccinatedOptions}
            onChange={handleChange('vaccinated')}
            value={localData.vaccinated}
            error={errors.vaccinated}
            required
            data-cy='vaccination-status-radio'
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default VaccinationStatus;
