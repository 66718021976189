/**
 * Check if email and kit code match a test kit registration
 */
export default async function isEmailKitCodeMatch (email, kitCode) {
  try {
    const url = `${process.env.REACT_API_URL}/test-kit-registration/search/by-email-and-kit-code?email=${email}&kitCode=${kitCode}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    });

    if (res.ok) {
      return {
        isSuccess: true,
        message: ''
      };
    }

    return {
      isSuccess: false,
      message: 'Email address does not match registered kit code.'
    };
  }
  catch (e) {
    return {
      isSuccess: false,
      message: 'Error occurred in fetching matching test kit registration.'
    };
  }
};
