import {
  Box,
  Link,
  Typography
} from '@mui/material';
import React from 'react';
import BulletNumberList from '../BulletNumberList';
import StepLayout from '../StepLayout';

const FitToFlyLfdInstructions = ({onClick, certType}) => {
  const youWillNeedSteps = certType === 'day2lfd'
    ? [<>Your booking reference number. If you don’t have one you can <Link href="/register/booking" target="_blank">create a new booking reference number</Link></>, 'Your test kit', 'Your passport details', 'Your flight details']
    : ['Your test kit', 'Your passport details', 'Your flight details'];

  return (
    <StepLayout
      onClick={onClick}
      onClickLabel="Start"
      title="Before you start"
    >

      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="body1" component="p">
            Customers can take up to 45 minutes to go through the whole process, including 15 minutes to wait for the result.
          </Typography>
        </Box>
        {certType === 'day2lfd' && (
          <Box mb={2}>
            <Typography variant="body1" component="p">
              Day 2 test has to be done on or before 2 days after arrival in the UK.
            </Typography>
          </Box>
        )}
        {certType === 'preDepartureCheck' && (
          <Box mb={2}>
            <Typography variant="body1" component="p">
              You can register your test before or after your kit arrives. If you choose to register before your kit arrives, you can select to take your test at a later date. Don&apos;t start your test before it is time. Check rules on offical websites.
            </Typography>
          </Box>
        )}
      </Box>
      <Box mb={4}>
        <Box mb={2.5}>
          <Typography variant="h4" component="h4">
            Here are the steps
          </Typography>
        </Box>
        <BulletNumberList steps={['Register test kit  (5 - 10 min)', 'Take test and wait for result (20 - 30 min)', 'Upload result (5 min)']} />
      </Box>
      <Box mb={2.5}>
        <Typography variant="h4" gutterBottom component="h4">
          You will need
        </Typography>
        <BulletNumberList steps={youWillNeedSteps} />
      </Box>
    </StepLayout>
  );
};

export default FitToFlyLfdInstructions;
