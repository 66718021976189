import {
  Button,
  ButtonGroup,
  Box,
  Typography
} from '@mui/material';
import React, {useState} from 'react';
import styled from 'styled-components';
import CaptionText from './CaptionText';

const styles = {
  '&.MuiButton-outlined': {
    color: '#2D2926DE',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  }
};

const StyledRoot = styled.div`
  & + &,
  .MuiTextField-root + & {
    margin-top: 20px;
  }
`;

const StyledError = styled(Typography)`
  color: #f44336
`;

const YesNo = ({
  label,
  onChange,
  error,
  defaultVal = null,
  dataCy
}) => {
  const [checked, setChecked] = useState(defaultVal);

  const handleClick = (val) => {
    setChecked(val);
    onChange(val);
  };

  return (
    <StyledRoot data-cy={dataCy}>
      <CaptionText>{label}</CaptionText>
      <ButtonGroup>
        <Button
          variant={checked === true ? 'contained' : 'outlined'}
          sx={styles}
          onClick={() => handleClick(true)}
        >
          Yes
        </Button>
        <Button
          variant={checked === false ? 'contained' : 'outlined'}
          sx={styles}
          onClick={() => handleClick(false)}
        >
          No
        </Button>
      </ButtonGroup>
      {error && (
        <Box mx={2} mt={0.5}>
          <StyledError component="p" variant="caption">
            {error}
          </StyledError>
        </Box>
      )}
    </StyledRoot>
  );
};

export default YesNo;
