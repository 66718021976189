import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';
import TextField from './TextField';

const PhoneNumberField = (props) => {
  return (
    <TextField
      as={MuiPhoneNumber}
      disableAreaCodes={true}
      autoFormat={false}
      countryCodeEditable={true}
      sx={{
        svg: {
          height: '20px'
        }
      }}
      {...props}
    />
  );
};

export default PhoneNumberField;
