import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ga from '../services/google_analytics';
import getTestKitJson from '../utils/getTestKitJson';
import regex from '../utils/regex';
import sentry from '../utils/sentry';
import validateKitCode from '../utils/validateKitCode';
import TextField from './TextField';

const logGaEvent = (valid) => {
  const suffix = valid ? 'VALID' : 'INVALID';
  const action = `KIT_CODE_ENTRY_${suffix}`;
  ga.event({
    category: 'F2FKitCodeEntry',
    action,
    nonInteraction: true
  });
};

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 20px;

  ${({$isSuccess}) => $isSuccess && `
    && input + fieldset,
    && input:focus + fieldset,
    && input:hover + fieldset {
      border-color: green;
    }
  `}
`;

const CheckTestKitCode = ({
  allowRegistered = false,
  onSuccessChange,
  onValidKitCode,
  testTypes,
  initialKitCode
}) => {
  const [kitCode, setKitCode] = useState('');
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setAndCheckKitCode(initialKitCode.toUpperCase());
  }, [initialKitCode]); // eslint-disable-line @medicspot/react-hooks/exhaustive-deps

  useEffect(() => {
    onSuccessChange(isSuccess);
  }, [isSuccess, onSuccessChange]);

  const handleChange = async (e) => {
    const value = e.target.value.toUpperCase();
    setAndCheckKitCode(value);
  };

  const setAndCheckKitCode = async (value) => {
    setKitCode(value);
    setIsSuccess(false);

    if (value === '') {
      setError(null);
    }
    else if (!regex.kitCode.test(value)) {
      setError('The kit code is 6 characters. Please double-check your package.');
    }
    else if (value === 'UN3373') {
      setError('UN3373 is not your kit code, it’s the shipment classification. Please double-check your package.');
    }
    else {
      try {
        const json = await getTestKitJson(value);
        const validateKitCodeRes = validateKitCode(json, testTypes, allowRegistered, value);

        setError(validateKitCodeRes.error);
        logGaEvent(validateKitCodeRes.logGaEvent);

        if (validateKitCodeRes.isSuccess) {
          setIsSuccess(true);
          onValidKitCode(value, json);
        }
      }
      catch (err) {
        sentry.captureException(err);
        setError('Error registering kit, please refresh and try again.');
      }
    }
  };

  return (
    <StyledTextField
      value={kitCode}
      error={error}
      $isSuccess={isSuccess}
      label="Kit code"
      helperText="e.g. ABC123"
      data-cy='kit-code-input'
      onChange={handleChange}
    />
  );
};

export default CheckTestKitCode;
