import React, {
  useEffect,
  useState
} from 'react';
import {
  useNavigate,
  useOutletContext,
  createSearchParams,
  useSearchParams,
  useParams
} from 'react-router-dom';
import certs from '../data/certs';
import kitVersions from '../data/kitVersions';
import StepsMap from '../data/stepsMap';
import isEmailKitCodeMatch from '../utils/emailKitCodeValidation';
import {getRegistration} from '../utils/getRegistration';
import getTestKitJson from '../utils/getTestKitJson';
import {generatePath} from '../utils/paths';
import regex from '../utils/regex';
import SelectCert from './SelectCert';

const Form = () => {
  const [
    onBackChild,
    setShowBack,
    setStages,
    setCurrentStage,
    setShowStages
  ] = useOutletContext();

  const {testKitCode} = useParams();
  const [pathIndex, setPathIndex] = useState(-1);
  const [registrationData, setRegistrationData] = useState({});
  const [submissionData, setSubmissionData] = useState({});
  const [cassetteImage, setCassetteImage] = useState(null);
  const [path, setPath] = useState([]);
  const [kitVersion, setKitVersion] = useState();
  const [cert, setCert] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  useEffect(() => {
    setShowStages(pathIndex >= 0);
  }, [setShowStages, pathIndex]);

  useEffect(() => {
    (async function () {
      const testKit = await getTestKitJson(testKitCode);
      const isValidEmail = regex.email.test(email);

      let jsonTestKitRegistration = {
        isSuccess: false,
        message: ''
      };

      // get testkit version info
      const kitVersion = kitVersions.findByVersion(testKit.kitVersion);
      setKitVersion(kitVersion);

      // get booking details
      if (testKit.valid && isValidEmail) {
        jsonTestKitRegistration = await isEmailKitCodeMatch(email, testKitCode);
      }

      // kitcode registration exists but no match to email is found, navigate to antigen submit
      if (testKit.valid && kitVersion.testType === 'RAT' && !testKit.available && !jsonTestKitRegistration.isSuccess) {
        const params = {kc: testKitCode, ...(isValidEmail && {email})};
        navigate({
          pathname: '/antigen/submit',
          search: `?${createSearchParams(params)}`
        });
        return;
      }

      // kitcode registration exists and email is valid, set path index to start of test submission stage
      if (testKit.valid && kitVersion.testType === 'RAT' && jsonTestKitRegistration.isSuccess && !testKit.completedAt) {
        const res = await getRegistration(email, testKitCode);
        if (res.success) {
          const fullCertType = res.data.registration.certificateTypes[0];
          const cert = certs.find(cert => cert.fullCert === fullCertType);
          const path = generatePath(cert.testType, cert.certType);
          const index = path.findIndex(step => step.stage === 'Take test');

          setRegistrationData((current) => ({
            ...current,
            bookingReference: res.data.bookingReference,
            kitVersion: res.data.kitVersion,
            kitCode: res.data.kitCode,
            departureDate: res.data.registration.formData.departureDate,
            email: res.data.registration.formData.email
          }));

          setSubmissionData((current) => ({
            ...current,
            bookingReference: res.data.bookingReference,
            kitVersion: res.data.kitVersion,
            kitCode: res.data.kitCode
          }));

          setCert(cert);
          setPathIndex(index);
          setPath(path);
        }
        return;
      }

      // kitcode and email present
      if (testKit.valid && testKitCode?.length && email?.length && kitVersion.testType === 'RAT') {
        const params = {kc: testKitCode, email};
        navigate({
          pathname: '/antigen/submit',
          search: `?${createSearchParams(params)}`
        });
        return;
      }

      // if kitcode invalid navigate to /register/code
      if (!testKit.valid || testKit.completedAt || !testKit.available) {
        const params = {kc: testKitCode};
        navigate({
          pathname: '/register/code',
          search: `?${createSearchParams(params)}`
        });
      }
    }());
  }, [testKitCode, navigate, email, kitVersion]);

  onBackChild.current = () => {
    if (pathIndex < 0 && cert) {
      setPath([]);
    }
    else {
      setPathIndex((currentIndex) => {
        return currentIndex - 1 < -1 ? -1 : currentIndex - 1;
      });
    };
  };

  useEffect(() => {
    if (pathIndex >= -1 && path.length) {
      const hideBack = path[pathIndex]?.hideBack;
      setShowBack(!hideBack);
    }
    else {
      setShowBack(false);
    }
  }, [setShowBack, pathIndex, path]);

  useEffect(() => {
    const stages = path.length ? Array.from(new Set(path.map(step => step.stage))) : [];
    setStages(stages);
  }, [path, setStages]);

  useEffect(() => {
    const {stage = '', stageProgress = 0} = pathIndex >= 0 && path.length ? path[pathIndex] : {};
    setCurrentStage({stage, progress: (stageProgress * 100)});
  }, [pathIndex, path, setCurrentStage]);

  return (
    <>
      {path.length && pathIndex >= 0
        ? (
          StepsMap[`${path[pathIndex].step}`](
            {
              path,
              setPath,
              setPathIndex,
              registrationData,
              setRegistrationData,
              submissionData,
              setSubmissionData,
              kitVersion,
              cassetteImage,
              setCassetteImage
            })
        )
        : <SelectCert
          path={path}
          setPath={setPath}
          setPathIndex={setPathIndex}
          setRegistrationData={setRegistrationData}
          currentCert={cert}
          setCert={setCert}
          validFor={kitVersion?.validFor}
        />
      }
    </>
  );
};

export default Form;
