import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import React from 'react';

const RadioButtons = ({
  label,
  options,
  onChange,
  value,
  error,
  required,
  'data-cy': dataCy
}) => {
  return (
    <Box mb={2}>
      <FormControl
        error={error}
        required={required}
        data-cy={dataCy}
      >
        {label && <Typography variant="h4" component="h4" gutterBottom>{label}</Typography>}
        <RadioGroup
          onChange={onChange}
          value={value}
        >
          {options.map(o => (
            <FormControlLabel key={o.value} value={o.value} control={<Radio />} label={o.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RadioButtons;
