import {Box} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const NhsDetails = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {nhsNumber = ''} = registrationData;
  const [localData, setLocalData] = useState({nhsNumber});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="NHS Details"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <TextField
            label="NHS Number (if known)"
            name="nhsNumber"
            value={localData.nhsNumber}
            error={errors.nhsNumber}
            onChange={handleChange('nhsNumber')}
            data-cy="nhs-number-input"
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default NhsDetails;
