import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import styled from 'styled-components';
import {Bold, List} from '../utils/commonComponents';
import {getAntigenSubmissionConfig} from '../utils/config';
import isEmailKitCodeMatch from '../utils/emailKitCodeValidation';
import getTestKitJson from '../utils/getTestKitJson';
import regex from '../utils/regex';
import validateKitCode from '../utils/validateKitCode';
import TextField from './TextField';

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 20px;
  
  ${({$isSuccess}) => $isSuccess && `
    && input + fieldset,
    && input:focus + fieldset,
    && input:hover + fieldset {
      border-color: green;
    }
  `}
  `;

const {allowRegistered, testTypes} = getAntigenSubmissionConfig();

const getErrorAction = (field, errorMessage, valid) => {
  const removeError = current => {
    const {[field]: removedKey, ...obj} = current;
    return obj;
  };

  const addError = current => ({
    ...current,
    [field]: errorMessage
  });

  const errorsAction = !valid
    ? addError
    : removeError;

  return errorsAction;
};

const AntigenSubmit = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const [kitCode, setKitCode] = useState(searchParams.get('kc') ?? '');
  const [email, setEmail] = useState(searchParams.get('email') ?? '');

  const [errors, setErrors] = useState({});
  const [isValidKitCode, setIsValidKitCode] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const initialKitCode = searchParams.get('kc');
  const initialEmail = searchParams.get('email');

  const handleKitCodeChange = async (value) => {
    const codeFormatted = value.toUpperCase();
    let isValid = false;
    let kitCodeErrorMessage = '';

    if (!regex.kitCode.test(codeFormatted)) {
      kitCodeErrorMessage = 'The kit code is 6 characters. Please double-check your package.';
    }
    else if (codeFormatted === 'UN3373') {
      kitCodeErrorMessage = 'UN3373 is not your kit code, it’s the shipment classification. Please double-check your package.';
    }
    else {
      const json = await getTestKitJson(codeFormatted);
      const validateKitCodeRes = validateKitCode(json, testTypes, allowRegistered, codeFormatted);

      if (validateKitCodeRes.isSuccess) {
        isValid = true;
      }

      else {
        kitCodeErrorMessage = validateKitCodeRes.error;
      }
    }

    setIsValidKitCode(isValid);
    setErrors(getErrorAction('kitCode', kitCodeErrorMessage, isValid));
    setKitCode(codeFormatted);
  };

  const handleEmailChange = async (value) => {
    let emailErrorMessage = 'Invalid email format.';
    let isValid = false;

    if (regex.email.test(value)) {
      isValid = true;
      emailErrorMessage = '';
    }

    setIsValidEmail(isValid);
    setErrors(getErrorAction('email', emailErrorMessage, isValid));
    setEmail(value);
  };

  const completeFormSubmission = async () => {
    setIsSubmitting(true);
    const json = await isEmailKitCodeMatch(email, kitCode);

    if (json.isSuccess) {
      navigate(`/register/${kitCode}?email=${email}`);
    }
    else {
      setSubmissionError(json.message);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (initialKitCode) {
        await handleKitCodeChange(initialKitCode);
      }

      if (initialEmail) {
        await handleEmailChange(initialEmail);
      }
    })();
  }, [initialKitCode, initialEmail]);

  return (
    <Box>
      <Box mb={3}>
        <Bold
          variant="h5"
          component="h1"
          gutterBottom
        >
              Submit your Rapid Antigen Test result
        </Bold>
      </Box>

      <Box>
        <Box mb={2}>
          <Bold component="h2" variant="body1">It is now time for you to submit your test result</Bold>
        </Box>
        <Box mb={2}>
          <List>
            <li>
              <Typography component="p" variant="body2">
                  Before you start, make sure you have completed your test and waited the required time.
              </Typography>
            </li>
            <li>
              <Typography component="p" variant="body2">
                  Test results can be read in 15-30 minutes from the moment you finish the test.
              </Typography>
            </li>
          </List>
        </Box>
      </Box>
      <Box>
        <Bold component="h2" variant="body1" gutterBottom>
            To continue with your registration, enter your test kit code and registered email address below:
        </Bold>
        <Box mt={2}>
          <Box mx={2}>
            <StyledTextField
              error={errors.kitCode}
              $isSuccess={isValidKitCode}
              value={kitCode}
              helperText={errors.kitCode ?? ''}
              label="Kit code"
              placeholder="e.g. ABC123"
              InputLabelProps={{
                shrink: true
              }}
              onChange={async (e) => {
                await handleKitCodeChange(e.target.value);
                setSubmissionError('');
              }}
            />
          </Box>

          <Box mx={2}>
            <StyledTextField
              error={errors.email}
              $isSuccess={isValidEmail}
              value={email}
              helperText={errors.email ?? ''}
              label="Email"
              name="email"
              InputLabelProps={{
                shrink: true
              }}
              onChange={async (e) => {
                await handleEmailChange(e.target.value);
                setSubmissionError('');
              }}
            />
          </Box>

          {submissionError && <Box mb={5}><Alert severity="error">{submissionError}</Alert></Box>}

          <Box display="flex" justifyContent="center" marginTop="30px">
            {
              isSubmitting
                ? (
                  <CircularProgress />
                )
                : (

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValidKitCode || !isValidEmail || !!Object.keys(errors).length}
                    onClick={completeFormSubmission}
                  >
                            Continue
                  </Button>
                )
            }
          </Box>

        </Box>
      </Box>
    </Box>
  );
};

export default AntigenSubmit;
