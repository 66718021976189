import {Box} from '@mui/material';
import {DateTime as LuxonDateTime} from 'luxon';
import React, {useState, useEffect} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import BulletNumberList from '../BulletNumberList';
import DateTimePicker from '../DateTime';
import StepLayout from '../StepLayout';

const TestTime = ({
  path,
  setPathIndex,
  submissionData,
  setSubmissionData
}) => {
  const {testDate = LuxonDateTime.local()} = submissionData;
  const [localData, setLocalData] = useState({testDate});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setSubmissionData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  // This video wasn't working - lets fine a new one and add back in
  // const videoUrl = 'https://www.youtube.com/watch?v=EnDK_19X6Tw';
  //  or <Link href={videoUrl} target="_blank">watch instructional video</Link></>

  return (
    <StepLayout
      onClick={onNextStep}
      onClickLabel="I have done my test"
      title="Take your test"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box mb={3}>
          <BulletNumberList steps={['Open your test kit', 'Take the test. Follow the instructions in the kit', 'Fill in the time when you did the test']} />
        </Box>
        <Box>
          <Box mb={2}>
            <DateTimePicker
              label='Time test was performed'
              value={localData.testDate}
              error={errors.testDate}
              onChange={handleChange('testDate')}
              required
              data-cy={'time-test-picker'}
            />
          </Box>
        </Box>
      </Box>
    </StepLayout>
  );
};

export default TestTime;
