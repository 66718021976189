import React from 'react';

const enabled = process.env.HUBSPOT_ENABLED === 'true';
const hubspotId = process.env.HUBSPOT_ID;

const useHubspot = () => {
  const [apiLoaded, setApiLoaded] = React.useState(false);
  const isEnabled = enabled && hubspotId;

  React.useEffect(() => {
    const script = document.createElement('script');

    if (isEnabled) {
      script.src = `//js.hs-scripts.com/${hubspotId}.js`;
      script.defer = true;
      document.body.appendChild(script);
      setApiLoaded(true);
    }

    return () => {
      if (isEnabled) {
        document.body.removeChild(script);
        setApiLoaded(false);
      }
    };
  }, [isEnabled]);

  return {
    apiLoaded
  };
};

export default useHubspot;
