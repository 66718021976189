export const toGMT = (date) => {
  if (!date || !date.isLuxonDateTime) {
    return date;
  }

  return date.setZone('Europe/London');
};

export const toPhoneNumber = (phoneNumberWithCode) => {
  return phoneNumberWithCode.replace(/[^0-9]+/g, '');
};

export const uppercase = (s) => s.toUpperCase();

export {
  toGMT as dateInPast,
  toGMT as timeOfSwab,
  toGMT as arrivalDate,
  toGMT as nonExemptCountryDate,
  toGMT as testDateTime,
  toPhoneNumber as phoneNumber,
  uppercase as bookingReference,
  uppercase as passengerLocatorReference,
  uppercase as msOrderNumber
};
