import {Link} from '@mui/material';
import React from 'react';
import kitVersions from '../data/kitVersions';

export default function validateKitCode (json, testTypes, allowRegistered, kitCode) {
  const kitVersion = kitVersions.findByVersion(json.kitVersion);
  const supportUrl = json.brand === 'medicspot' ? 'https://medicspot.co.uk/contact' : 'https://outcomedx.co.uk/contact';

  if (testTypes && kitVersion?.testType && !testTypes.includes(kitVersion?.testType)) {
    return {
      error: <>This type of test is not accepted by this form, please <Link href={supportUrl}>contact support</Link> for assistance.</>,
      logGaEvent: false
    };
  }
  else if (json && json.completedAt) {
    return {
      error: 'This kit code has already been registered and used.',
      logGaEvent: false
    };
  }
  else if (json && json.contactSupport) {
    return {
      error: <>Unfortunately this kit cannot be registered, please <Link href={supportUrl}>contact support</Link> to obtain a new kit.</>,
      logGaEvent: false
    };
  }
  else if (json && json.available) {
    return {
      error: null,
      isSuccess: true,
      logGaEvent: true
    };
  }
  else if (json && !json.valid) {
    return {
      error: 'We don’t recognise this kit code. Please double-check your package.',
      logGaEvent: false
    };
  }
  else if (json && !json.available && !allowRegistered) {
    return {
      error: kitVersion.testType === 'RAT'
        ? <>This kit code has already been registered. If you are ready to take and submit your test, <Link href={`/antigen/submit?kc=${kitCode}`} target='_blank'>visit the submission form</Link>.</>
        : 'This kit code has already been registered.',
      logGaEvent: false
    };
  }
  else if (json && allowRegistered && !json.available) {
    return {
      error: null,
      isSuccess: true,
      logGaEvent: false
    };
  }
  else {
    return {
      error: 'Invalid code',
      logGaEvent: false
    };
  }
}
