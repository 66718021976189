import {CameraAlt as CameraIcon} from '@mui/icons-material';
import {
  Button,
  Box,
  Typography,
  Alert
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {get} from 'lodash';
import React, {
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import mixpanel from '../../services/mixpanel';
import makePathIndex from '../../utils/pathIndex';
import {image as validateImage} from '../../utils/validators';
import InfoBox from '../InfoBox';
import StepLayout from '../StepLayout';

const Image = styled.img`
  max-width: 100%;
  max-height: 600px;
`;

const useStyles = makeStyles({
  fileInput: {
    display: 'none'
  }
});

const UploadPhoto = ({
  path,
  setPathIndex,
  registrationData,
  setCassetteImage
}) => {
  const classes = useStyles();
  const [imageError, setImageError] = useState(null);
  const fileUploadEl = useRef();

  const onNextStep = makePathIndex(path, setPathIndex);
  const onImage = async (image) => {
    const validImage = await validateImage(image);
    if (validImage.isValid) {
      setCassetteImage(image);
      onNextStep();
    }
    else {
      setImageError(validImage.message);
      mixpanel.track('AntigenForm_AntigenCassette', {
        kitVersion: registrationData.kitVersion,
        kitCode: registrationData.kitCode,
        errorMessage: validImage.message
      });
    }
  };

  return (
    <StepLayout
      title="Take a photo of your result"
      header={
        <Box mb={2.5} display="flex" justifyContent="center">
          <Image src="https://res.cloudinary.com/medicspot/image/upload/v1645530150/covid-test.png" alt="Layout" />
        </Box>
      }
    >
      <Box mb={3}>
        <Box>
          <Box>
            <Box mb={0.5}>
              <Typography component="p" variant="body1" gutterBottom>
                Take a photo of the cassette when the result is ready.
              </Typography>
            </Box>
            <Box mb={1}>
              <InfoBox
                message="Make sure the image is lit and sharp, and the test cassette can be clearly read."
                level="infoLight"
              />
            </Box>
            <Box>
              {imageError && <Box mb={3}><Alert severity="error">{imageError}</Alert></Box>}
              <input
                type="file"
                ref={fileUploadEl}
                className={classes.fileInput}
                data-cy='cassette-upload-input'
                accept='image/jpeg,image/png,image/jpg'
                onChange={(e) => {
                  const file = get(e.target, 'files[0]');
                  if (file) {
                    onImage(file);
                  }
                }}
              />
              <Box mb={0.5}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => fileUploadEl.current.click()}
                >
                  <Box mr={1} display="flex"><CameraIcon /></Box>
                  <Box>
                    Upload photo
                  </Box>
                </Button>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography component="p" variant="caption">Images must be in JPEG or PNG format and less than 5 MB</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </StepLayout>
  );
};

export default UploadPhoto;
