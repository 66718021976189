import {getTestKitIdCheckUrl} from '../utils/urls';

export default async function getTestKitJson (value) {
  const url = getTestKitIdCheckUrl(value);

  const json = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }).then(r => r.json());

  return json;
}
