const onNextStep = (validate, setData, setPathIndex, localData, path) => () => {
  let validForm = true;
  for (const field in localData) {
    validForm &= validate(field, localData[field]);
  }

  if (validForm) {
    setData((current) => ({
      ...current,
      ...localData
    }));
    setPathIndex((currentIndex) => {
      return currentIndex + 1 < path.length ? currentIndex + 1 : currentIndex;
    });
  }
};

export default onNextStep;
