import {Box} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {disableNextStep as disableNextStepFn} from '../../utils/disableNextStep';
import makeHandleChange from '../../utils/handleChange';
import makeOnNextStep from '../../utils/onNextStep';
import makeValidate from '../../utils/validate';
import CountrySelect from '../CountrySelect';
import StepLayout from '../StepLayout';
import TextField from '../TextField';

const Passport = ({
  path,
  setPathIndex,
  setRegistrationData,
  registrationData
}) => {
  const {passportIssuer = '', passportNumber = ''} = registrationData;
  const [localData, setLocalData] = useState({passportIssuer, passportNumber});
  const [errors, setErrors] = useState({});

  const validate = makeValidate(setErrors, localData);
  const handleChange = makeHandleChange(validate, setLocalData);
  const onNextStep = makeOnNextStep(validate, setRegistrationData, setPathIndex, localData, path);
  const [disableNextStep, setDisableNextStep] = useState(true);

  useEffect(() => {
    disableNextStepFn(localData, makeValidate(null, localData), setDisableNextStep);
  }, [disableNextStep, localData]);

  return (
    <StepLayout
      onClick={onNextStep}
      title="Your passport information"
      onClickDisabled={disableNextStep}
    >
      <Box mb={3}>
        <Box>
          <CountrySelect
            label="Passport issuer"
            name="passportIssuer"
            value={localData.passportIssuer}
            error={errors.passportIssuer}
            onChange={handleChange('passportIssuer')}
            required
            helperText="The country that issued the passport"
            data-cy="passport-issuer-select"
          />
          <TextField
            label="Passport number"
            value={localData.passportNumber}
            error={errors.passportNumber}
            onChange={handleChange('passportNumber')}
            required
            data-cy="passport-number-input"
          />
        </Box>
      </Box>
    </StepLayout>
  );
};

export default Passport;
