import mixpanel from 'mixpanel-browser';

/**
 * initMixpanel
 * @param {Object}  options
 * @param {String}  options.token - api token
 * @param {Boolean} options.enabled
 * @param {Object}  options.initOptions - options to be passed to mixpanel.init
 * @param {Object}  options.registerOptions - options to be passed to mixpanel.register
 * @return {Object} Mixpanel
 *
 * https://developer.mixpanel.com/docs/javascript-full-api-reference
 */
const initMixpanel = ({
  token,
  enabled,
  initOptions = {},
  registerOptions = {}
}) => {
  // stub out methods if not enabled as mixpanel doesn't have an api for
  // enabling/disabling sending *all* events
  if (!enabled) {
    return {
      track: () => {}
    };
  }

  // this allows us to easily filter by app in mixpanel reports
  if (!registerOptions.app) {
    throw Error('You must provide registerOptions.app to mixpanel for tracking');
  }

  mixpanel.init(token, {
    ...initOptions,
    // EU Data Residency
    api_host: 'https://api-eu.mixpanel.com'
  });

  // set default attributes that will added to all `mixpanel.track` events
  mixpanel.register({
    ...registerOptions
  });

  return mixpanel;
};

export default initMixpanel;
