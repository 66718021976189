import ReactGA from 'react-ga';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
  testMode: process.env.NODE_ENV === 'test',
  gaOptions: {
    debug: process.env.LOG_LEVEL === 'debug',
    app: 'web-testkit',
    vendor: 'medicspot'
  }
});

export default ReactGA;
