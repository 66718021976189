import {round} from 'lodash';

/**
 * Displays the difference between two dates as a readable string
 * Optional unit parameter to specify which units you want. Defaults to hours and minutes
 *
 * @param {DateTime} date1 the later date
 * @param {DateTime} date2 the earlier date
 * @param {Array<String>} unit Units you want to display
 * @returns {String}
 */
const dateDifferenceString = (date1, date2, unit = ['hours', 'minutes']) => {
  const diff = date1.diff(date2, unit).toObject();

  const values = [
    diff.years ? `${round(diff.years)} years` : null,
    diff.months ? `${round(diff.months)} months` : null,
    diff.days ? `${round(diff.days)} days` : null,
    diff.hours ? `${round(diff.hours)}h` : null,
    diff.minutes ? `${round(diff.minutes)}m` : null
  ];

  return values.filter(Boolean).join(' ');
};

export default dateDifferenceString;
