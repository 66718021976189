import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Alert
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import makePathIndex from '../../utils/pathIndex';
import StepLayout from '../StepLayout';

const handleSubmission = async ({cassetteImage, submissionData}) => {
  const cassetteUrl = `${process.env.REACT_API_URL}/file-upload?${new URLSearchParams({
    bucketType: 'testkit-rats',
    fileName: cassetteImage.name,
    mimeType: cassetteImage.type
  })}`;

  try {
    const cassetteRes = await fetch(cassetteUrl).then(r => r.json());
    const cassetteUploadRes = await fetch(cassetteRes.signedUrl, {
      method: 'PUT',
      body: cassetteImage
    });

    if (!cassetteUploadRes.ok) {
      const e = new Error('Could not upload image');
      e.name = 'imageUpload';
      throw e;
    }

    const submissionRes = await fetch(`${process.env.REACT_API_URL}/test-kit-submissions`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...submissionData,
        cassetteImage: {
          url: cassetteRes.signedUrl,
          key: cassetteRes.key
        }
      })
    });

    if (!submissionRes.ok) {
      const e = new Error('Could not save your submission, please try again');
      e.name = 'saveSubmission';
      throw e;
    }

    return {
      success: true,
      message: ''
    };
  }
  catch (e) {
    const errorMessage = ['saveSubmission', 'imageUpload'].includes(e.name) ? e.message : 'Could not save your submission, please try again';

    return {
      success: false,
      message: errorMessage
    };
  }
};

const SubmissionConfirmation = ({
  path,
  setPathIndex,
  cassetteImage,
  submissionData
}) => {
  const [localData, setLocalData] = useState({checkedIdentity: '', checkedImages: '', checkedTime: ''});
  const [submissionError, setSubmissionError] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const onNextStep = makePathIndex(path, setPathIndex);

  useEffect(() => {
    const {checkedIdentity, checkedImages, checkedTime} = localData;
    setSubmitEnabled(checkedIdentity && checkedImages && checkedTime);
  }, [localData]);

  const handleChange = (field) => eventData => {
    const value = eventData.target ? eventData.target.value : eventData;
    const update = {
      [field]: value
    };
    setLocalData(current => ({...current, ...update}));
  };

  return (
    <StepLayout
      onClickLabel='Submit the result'
      onClickDisabled={!submitEnabled}
      onClick={async () => {
        setSubmitEnabled(false);
        const result = await handleSubmission({cassetteImage, submissionData});
        if (result.success) {
          onNextStep();
        }
        else {
          setSubmissionError(result.message);
        }
        setSubmitEnabled(true);
      }} title="Submission Confirmation">
      <Box mb={3}>
        <Box>
          <Box mb={2}>
            <Typography component="p" variant="body2" gutterBottom>
              You have arrived at the last step of the registration process.
            </Typography>
          </Box>
          <Box mb={3}>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Checked identity"
                    data-cy='identity-checkbox'
                    checked={localData.checkedIdentity}
                    onChange={(event) => handleChange('checkedIdentity')(event.target.checked)}
                  />}
                label={
                  <Typography component="p" variant="body2" gutterBottom>
                    I confirm the identity of the person who filled in the registration form is the same as the person who self-administered the swab.
                  </Typography>
                }
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={<Checkbox
                  name="Checked time"
                  data-cy='specified-time-checkbox'
                  checked={localData.checkedTime}
                  onChange={(event) => handleChange('checkedTime')(event.target.checked)}
                />}
                label={
                  <Typography component="p" variant="body2" gutterBottom>
                    I confirm that I took a photo of my test results within the time specified in my kit’s instructions.
                  </Typography>
                }
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={<Checkbox
                  name="Checked Images"
                  data-cy='images-checkbox'
                  checked={localData.checkedImages}
                  onChange={(event) => handleChange('checkedImages')(event.target.checked)}
                />}
                label={
                  <Typography component="p" variant="body2" gutterBottom>
                    I understand that an incorrect or illegible image will void my test.
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!!submissionError.length && (
        <Box display="flex" justifyContent="center" my={3}>
          <Alert severity="error">{submissionError}</Alert>
        </Box>
      )}
    </StepLayout>
  );
};

export default SubmissionConfirmation;
