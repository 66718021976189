import 'core-js/stable';
import 'regenerator-runtime/runtime';
import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {createGlobalStyle} from 'styled-components';
import App from './components/App';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    background-color: #FAFAFA;
  }
`;

const theme = createTheme({
  palette: {
    primary: {
      main: '#307FE2',
      dark: '#0D4C80'
    },
    text: {
      primary: '#2D2926DE',
      contrast: '#ffffff'
    },
    infoBoxBackground: {
      info: '#2196F3',
      infoLight: '#E8F4FE',
      warning: '#F9E17D',
      success: '#4CAF50'
    }
  },
  typography: {
    fontFamily: 'Gilroy, "Work Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: 42
    },
    h2: {
      fontWeight: 700,
      fontSize: 24
    },
    h3: {
      fontWeight: 500,
      fontSize: 20
    },
    h4: {
      fontWeight: 600,
      fontSize: 17
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: -0.04
    },
    h6: {
      fontSize: 16,
      fontWeight: 500
    },
    button: {
      textTransform: 'none',
      fontWeight: 700,
      fontSize: 15
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600
    },
    body1: {
      fontSize: 16
    },
    body2: {
      fontSize: 14
    }
  }
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <GlobalStyles />
        <LocalizationProvider dateAdapter={DateAdapter}>
          <App />
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </BrowserRouter>, document.getElementById('root'));
