import React from 'react';
import Address from '../components/steps/Address';
import Booking from '../components/steps/Booking';
import ConfirmDetails from '../components/steps/ConfirmDetails';
import DeliveryTrackingNumber from '../components/steps/DeliveryTrackingNumber';
import EmailAndPhone from '../components/steps/EmailAndPhone';
import IsolatingAddress from '../components/steps/IsolatingAddress';
import LFDSubmission from '../components/steps/LFDSubmission';
import Names from '../components/steps/Names';
import NextSteps from '../components/steps/NextSteps';
import NhsDetails from '../components/steps/NhsDetails';
import OutboundTravelDetails from '../components/steps/OutboundTravelDetails';
import Passport from '../components/steps/Passport';
import PCRSubmission from '../components/steps/PCRSubmission';
import PersonalInfo from '../components/steps/PersonalInfo';
import ReasonForTest from '../components/steps/ReasonForTest';
import ReturnTravelDetails from '../components/steps/ReturnTravelDetails';
import ReviewPhoto from '../components/steps/ReviewPhoto';
import SelfReviewResult from '../components/steps/SelfReviewResult';
import SubmissionConfirmation from '../components/steps/SubmissionConfirmation';
import SwabDetails from '../components/steps/SwabDetails';
import Symptoms from '../components/steps/Symptoms';
import TestTime from '../components/steps/TestTime';
import UploadPhoto from '../components/steps/UploadPhoto';
import VaccinationStatus from '../components/steps/VaccinationStatus';
import WaitResult from '../components/steps/WaitResult';

const StepsMap = {
  // registration steps
  names: (props) => <Names {...props}/>,
  personalInfo: (props) => <PersonalInfo {...props}/>,
  vaccinationStatus: (props) => <VaccinationStatus {...props}/>,
  emailAndPhone: (props) => <EmailAndPhone {...props}/>,
  address: (props) => <Address {...props}/>,
  reasonForTest: (props) => <ReasonForTest {...props}/>,
  passport: (props) => <Passport {...props}/>,
  booking: (props) => <Booking {...props}/>,
  symptoms: (props) => <Symptoms {...props}/>,
  nhsDetails: (props) => <NhsDetails {...props}/>,
  isolatingAddress: (props) => <IsolatingAddress {...props}/>,
  swabDetails: (props) => <SwabDetails {...props}/>,
  confirmDetails: (props) => <ConfirmDetails {...props}/>,
  returnTravelDetails: (props) => <ReturnTravelDetails {...props}/>,
  outboundTravelDetails: (props) => <OutboundTravelDetails {...props}/>,
  deliveryTrackingNumber: (props) => <DeliveryTrackingNumber {...props}/>,

  // test steps
  nextSteps: (props) => <NextSteps {...props}/>,
  testTime: (props) => <TestTime {...props}/>,
  waitResult: (props) => <WaitResult {...props}/>,
  uploadPhoto: (props) => <UploadPhoto {...props}/>,
  reviewPhoto: (props) => <ReviewPhoto {...props}/>,
  selfReviewResult: (props) => <SelfReviewResult {...props}/>,

  // submission steps
  submissionConfirmation: (props) => <SubmissionConfirmation {...props}/>,
  pcrSubmission: (props) => <PCRSubmission {...props}/>,
  lfdSubmission: (props) => <LFDSubmission {...props}/>
};

export default StepsMap;
