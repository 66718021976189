import {Box, LinearProgress} from '@mui/material';
import React from 'react';

const LinearProgressBar = ({value}) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%">
        <LinearProgress variant="determinate" value={value} />
      </Box>
    </Box>
  );
};

export default LinearProgressBar;
