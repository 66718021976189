import {Typography, Box, Alert} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

const Verification = () => {
  const [error, setError] = useState('');
  const [testKit, setTestKit] = useState('');
  const {uuid} = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${process.env.REACT_API_URL}/test-kits/uuid/${uuid}`, {
          headers: {
            Accept: 'application/json'
          }
        });

        if (res.status === 404) {
          setError('The url is not valid meaning this certificate cannot be verified, please contact support');
        }
        else {
          const json = await res.json();
          setTestKit(json);
        }
      }
      catch (e) {
        setError(`There was an error. Please refresh the page. ${e.stack}`);
      }
    })();
  }, []); // eslint-disable-line @medicspot/react-hooks/exhaustive-deps

  const {userDetails, labDetails} = testKit;

  return (
    <>
      {error && (
        <Alert severity="error">{error}</Alert>
      )}
      <Box display="flex" justifyContent="center">
        <Typography variant="h5" component="h1">
          <strong>COVID-19 certificate verification</strong>
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h6" component="h2">
          <strong>User details:</strong>
        </Typography>
        <Typography>
          <strong>Full name:</strong> {userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : null}
        </Typography>
        <Typography>
          <strong>Date of birth:</strong> {userDetails ? userDetails.dateOfBirth : null}
        </Typography>
        <Typography>
          <strong>Email:</strong> {userDetails ? userDetails.email : null}
        </Typography>
        <Typography>
          <strong>Passport number:</strong> {userDetails ? userDetails.passportNumber : null}
        </Typography>
        <Typography>
          <strong>Passenger locator ID:</strong> {userDetails ? userDetails.orderReference : null}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h6" component="h2">
          <strong>Lab details:</strong>
        </Typography>
        <Typography>
          <strong>Lab name:</strong> {labDetails ? labDetails.labName : null}
        </Typography>
        <Typography>
          <strong>Lab result:</strong> {labDetails ? labDetails.result : null}
        </Typography>
        <Typography>
          <strong>Lab reference:</strong> {labDetails ? labDetails.labRef : null}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h6" component="h2">
          <strong>Kit details:</strong>
        </Typography>
        <Typography>
          <strong>Kit code:</strong> {testKit.kitCode}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h6" component="h2">
          {testKit.status === 'completed' &&
            <p>We can confirm that this certificate is valid.</p>}
        </Typography>
      </Box>
    </>
  );
};

export default Verification;
